import {
  AspectRatio,
  Box,
  Img,
  Container,
  forwardRef,
  Heading,
  Input,
  Stack,
  Text,
  chakra,
  Spinner,
} from '@chakra-ui/react';
import { FaTrash, FaUpload } from 'react-icons/fa';
import { FC, useRef, useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { Flex } from '@chakra-ui/layout';
import { useFileUploadApi } from '../hooks/useFileUpload';
import { ImageListType } from 'react-images-uploading/';
import Notification from '../notification/notification';
import { useTranslation } from 'react-i18next';
import { set } from 'date-fns';

const FaTrashAlt = chakra(FaTrash);
const FaUploadAlt = chakra(FaUpload);

interface UploadImageProps {
  onFileUpload: (filePath: string) => void;
}
export const UploadImage: FC<UploadImageProps> = ({ onFileUpload }) => {
  const [images, setImages] = useState<ImageListType>([]);
  const { uploadFile } = useFileUploadApi();
  const [isLoading, setIsLoading] = useState(false);
  const { t: translate } = useTranslation();
  const resetBtn = useRef<any>(null);

  const onChange: (value: ImageListType) => void = async (imageList) => {
    if (imageList[0].file) {
      if (imageList[0].file.size > 1024 * 1024 * 2) {
        Notification.warning(translate('common.alert.maximumImageSize'));
        resetBtn?.current?.click();
        return;
      }
      setIsLoading(true);
      const filePath = await uploadFile(imageList[0].file);
      onFileUpload(filePath);
      setImages(imageList);
      setIsLoading(false);
    }
  };
  return (
    <ImageUploading value={images} onChange={onChange} dataURLKey="data_url">
      {({ imageList, onImageUpload, onImageRemoveAll, dragProps }) => (
        <Box
          width={'100%'}
          height={'150px'}
          borderColor="gray.300"
          borderStyle="dashed"
          borderWidth="2px"
          rounded="md"
          shadow="sm"
          _hover={{
            shadow: 'md',
          }}
          onClick={() => {
            if (!isLoading) onImageUpload();
          }}
          {...dragProps}
        >
          {!isLoading &&
            imageList.map((image, index) => (
              <>
                <button style={{ display: 'none' }} ref={resetBtn} onClick={onImageRemoveAll}></button>
                <Flex mt={3} mr={3} justifyContent={'flex-end'} key={`${index}`}>
                  <FaTrashAlt cursor={'pointer'} color={'red.700'} onClick={onImageRemoveAll} mr={'1rem'} />
                  <FaUploadAlt cursor={'pointer'} color={'green.700'} onClick={onImageUpload} />
                </Flex>
                <Img objectFit={'contain'} key={index} src={image['data_url']} alt="" width="100%" height={'100px'} />
              </>
            ))}

          {!imageList.length && !isLoading && (
            <Box position="relative" height="100%" width="100%">
              <Box
                position="absolute"
                top="0"
                left="0"
                height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
              >
                <Stack height="100%" width="100%" display="flex" alignItems="center" justify="center" spacing="4">
                  <Stack p="8" textAlign="center" spacing="1">
                    <Heading fontSize="lg" color="gray.700" fontWeight="bold">
                      Drop images here
                    </Heading>
                    <Text fontWeight="light">or click to upload</Text>
                  </Stack>
                </Stack>
              </Box>
            </Box>
          )}
          {isLoading && (
            <Flex w="full" h="full" alignItems="center" justifyContent="center">
              <Spinner />
            </Flex>
          )}
        </Box>
      )}
    </ImageUploading>
  );
};
