import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import ProfileView from '../public/ProfileView/ProfileView';
import AuthenticationContainer from './authentication.container';
import ExplorePublicUsers from 'app/features/public/search/Search-container';

const Login = lazy(() => import('./views/login/login.container'));
const Register = lazy(() => import('./views/register/register.container'));
const ResetPassword = lazy(() => import('./views/reset-password/ResetPassword'));
const ForgetPassword = lazy(() => import('./views/forget-password/ForgetPassword'));
const Status = lazy(() => import('./views/status/Status'));

export default [
  <Route path={'/'} element={<AuthenticationContainer />}>
    <Route path="/login" element={<Login />} />
    <Route path="/zoeken" element={<ExplorePublicUsers />} />
    <Route path="/profile/:id" element={<ProfileView />} />
    <Route path="/register" element={<Register />} />
    <Route path="/forget-password" element={<ForgetPassword />} />
    <Route path="/reset-password/:token" element={<ResetPassword />} />
    <Route path="/status/:userId" element={<Status />} />
    <Route path="/" element={<Navigate replace to="/register" />} />
  </Route>,
];
