import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { ProfileState } from './domain/profile.state';
import { UserModel } from './domain/currentUser.model';
import { GetChargeResponse } from './domain/response/ChargeResponse';
import { EducationModel } from './domain/education.model';
import { AdressModal } from './domain/AdressModal';

export const initialState: ProfileState = {
  loading: false,
};

const ProfileSlice: Slice<ProfileState> = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<UserModel>) => {
      state.currentUser = action.payload;
    },
    setCharge: (state, action: PayloadAction<GetChargeResponse[]>) => {
      state.chargeList = action.payload;
    },
    addCharge: (state, action: PayloadAction<GetChargeResponse>) => {
      state.chargeList = [...(state.chargeList ?? []), action.payload];
    },
    editCharge: (state, action: PayloadAction<GetChargeResponse>) => {
      state.chargeList =
        state.chargeList?.map((item) => {
          if (item?.id == action.payload.id) {
            return action.payload;
          } else {
            return item;
          }
        }) ?? [];
    },
    removeCharge: (state, action: PayloadAction<string>) => {
      state.chargeList = state.chargeList?.filter((e) => e.id != action.payload);
    },
    startLoading: (state) => {
      state.loading = true;
    },
    setProfileLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setEducationList: (state, action: PayloadAction<EducationModel[]>) => {
      state.educationList = action.payload;
    },
    setAddressList: (state, action: PayloadAction<AdressModal[]>) => {
      state.addresses = action.payload;
    },
    setPreferredAddress: (state, action: PayloadAction<AdressModal>) => {
      state.preferredAddress = action.payload;
    },
    setError: (state, action: PayloadAction<Error>) => {
      state.error = action.payload;
    },
    removeEducation: (state, action: PayloadAction<string>) => {
      state.educationList = state.educationList?.filter((e) => e.id != action.payload);
    },
  },
});

export const {
  setCurrentUser,
  setCharge,
  addCharge,
  editCharge,
  removeCharge,
  setEducationList,
  setError,
  removeEducation,
  setAddressList,
  setPreferredAddress,
  setProfileLoading,
} = ProfileSlice.actions;

const ProfileReducer = ProfileSlice.reducer;
export default ProfileReducer;
