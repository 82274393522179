import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

const Documents = lazy(() => import('./Documents'));

export default [
  <Route path="documents">
    <Route path="" element={<Documents />} />
  </Route>,
];
