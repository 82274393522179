import { FunctionComponent, ReactNode, useState } from 'react';
import { Button, FormControl, FormErrorMessage, Input, InputGroup, InputLeftElement, InputRightElement, Spinner } from '@chakra-ui/react';
import { FieldHookConfig, useField } from 'formik';
import FormattedMessage from '../../translation/formattedMessage';
import MaskedInput from 'react-text-mask';
import { CheckIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import './input.scss'
import { createVatIdMask } from './vat-id-mask';
// @ts-ignore
interface ICustomFieldProps extends FieldHookConfig<any> {
  icon?: ReactNode;
  type?: string;
  placeholder: string;
  name: string;
  disabled?: boolean;
  defaultValue?: string;
  isVat?: boolean;
  onPaste?: (e?: any) => void;
  onBlur?: (e?: any) => void;
  loading?: boolean;
  showIcon?: string;
  countryCode?: any;
  onChange?: (e: any) => void;
  value?: string;
  maxLength?: number
}

const CustomInput: FunctionComponent<ICustomFieldProps> = ({ icon, countryCode, isVat = false, ...props }) => {  
  const [show, setShow] = useState(false);
  const [field, meta] = useField(props);
  const { t: translate, i18n } = useTranslation();

  return (
    <FormControl isInvalid={meta.touched && !!meta.error}>
      {props.type !== 'password' ? (
        <InputGroup>
          {icon && <InputLeftElement pointerEvents="none" children={icon} />}
          {!isVat ? (
            <Input {...field} {...props} />
          ) : (
            <div className='d-flex flex-column full-width'>
            <InputGroup>
              <Input {...field} {...props} />
              <InputRightElement>
                {props.loading ? <Spinner size='xs' /> : props.showIcon === 'checkSuccess' ? <CheckIcon color='green.500' /> : props.showIcon === 'failed' ? <SmallCloseIcon color='red.500' /> : null} 
              </InputRightElement>
            </InputGroup>
            {props.showIcon === 'failed' && <div className='error'>{translate("authentification.register.form.input.vat.error")}</div>}
            </div>
          )}{' '}
        </InputGroup>
      ) : (
        <InputGroup>
          <Input {...field} {...props} type={show ? 'text' : 'password'} />
          {icon && <InputLeftElement pointerEvents="none" children={icon} />}
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={() => setShow(!show)}>
              {show ? 'Hide' : 'Show'}
            </Button>
          </InputRightElement>
        </InputGroup>
      )}
      <FormErrorMessage>
        <FormattedMessage translationKey={meta.error as string} />
      </FormErrorMessage>
    </FormControl>
  );
};

export default CustomInput;
