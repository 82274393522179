import { useDispatch } from 'react-redux';

import { postFileUploadApi, postMultipleFilesUploadApi } from '../api/api';
import { IFileUploadResponse } from '../../models/file-upload-response.model';

interface IUseFileUpload {
  uploadFile: (file: File) => Promise<string>;
  uploadMultipleFiles: (files: File[]) => Promise<string[]>;
}

export const useFileUploadApi = (): IUseFileUpload => {
  const dispatch = useDispatch();

  const uploadFile: IUseFileUpload['uploadFile'] = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    const result = await postFileUploadApi(formData);
    return result.filename;
  };

  const uploadMultipleFiles: IUseFileUpload['uploadMultipleFiles'] = async (files: File[]) => {
    const formData = new FormData();
    files.forEach((file: File) => {
      formData.append('file', file);
    });
    const result = await postMultipleFilesUploadApi(formData);
    return result?.map((item: IFileUploadResponse) => item?.filename);
  };

  return { uploadFile, uploadMultipleFiles };
};
