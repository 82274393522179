import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EventCreateContainer from './views/eventCreate/eventCreate.container';
const EventContainer = lazy(() => import('./event.container'));
const EventOverviewContainer = lazy(() => import('./views/eventOverview/eventOverview.container'));
const EventDetailContainer = lazy(() => import('./views/eventDetail/eventDetail.container'));

export default [
  <Route path="event" element={<EventContainer />}>
    <Route path="" element={<EventOverviewContainer />} />
    <Route path=":id" element={<EventDetailContainer />} />
    <Route path="create" element={<EventCreateContainer />} />
  </Route>,
];
