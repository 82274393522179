import { FC, FunctionComponent, useState } from 'react';
import { Img, Flex, Box, Link } from '@chakra-ui/react';
import logo from '../../../assets/images/logo.svg';
import logoWhite from '../../../assets/images/vvtiv-05-4.png'
import { useLocation, Link as ReactRouterLink } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';

const Header: FunctionComponent = () => {
  const { pathname } = useLocation();
  const [showMenu, setShowMenu] = useState<boolean>(false)

  const screenWidth = window.screen.width;

  const isZoekenPage = pathname == '/zoeken';
  return (
    <Flex bg={isZoekenPage ? 'black' : 'white'} direction="column" width="100wh" mb={0}>
      <Flex flexDirection="row" alignItems="center" px={7} justifyContent={screenWidth <= 1024 ? "space-between" : "center"}>
        <Img src={isZoekenPage ? logoWhite : logo} pt={10} pb={5} w={isZoekenPage && screenWidth <= 1024 ? '120px' : isZoekenPage ? '240px' : ''} h={'145px'} height={isZoekenPage ? 'auto' : '160px'} mb={isZoekenPage ? '30px' : 'unset'} />
        {/* <LangaugeSelect mr="2rem" mt="-2rem" ml="auto" color={'black'} /> */}
        {screenWidth <= 1024 && isZoekenPage && (
          <HamburgerIcon color={'#fff'} fontSize={20} onClick={() => setShowMenu(!showMenu)} />
        )}
      </Flex>
      {isZoekenPage && <Navbar mobileMenu={screenWidth <= 1024} showMenu={showMenu} />}
    </Flex>
  );
};

interface INavbarItems {
  href: string;
  text: string;
}

interface NavbarProps {
  showMenu?: boolean;
  mobileMenu?: boolean;
}

const Navbar: FC<NavbarProps> = ({ mobileMenu, showMenu }) => {

  const items: INavbarItems[] = [
    {
      href: 'https://vvtiv.be/index.html',
      text: 'VVTIV',
    },
    {
      href: 'https://vvtiv.be/?page_id=3103',
      text: 'VOOR LEDEN',
    },
    {
      href: 'https://vvtiv.be/interactionele-vormgeving.html',
      text: 'INTERACTIONELE VORMGEVING',
    },
    {
      href: '#',
      text: 'VIND EEN IV THERAPEUT',
    },
    {
      href: 'https://vvtiv.be/contact/',
      text: 'CONTACT',
    },
    {
      href: 'https://leden.vvtiv.be/login',
      text: 'LOG IN',
    },
  ];

  return (
    <>{
      !mobileMenu ? (
        <Flex justifyContent="center" gap="5rem" paddingBottom="2rem" position="relative" top={'-10px'}>
          {items.map(({ href, text }, index) => (
            <Box key={index} color="white" _hover={{ color: 'primary.600', cursor: 'pointer' }}>
              <Link
                to={href}
                as={ReactRouterLink}
                _hover={{ textDecoration: 'none' }}
                fontWeight={400}
                fontStyle={'normal'}
                fontSize={'14px'}
              >
                {text}
              </Link>
            </Box>
          ))}
        </Flex>
      ) : showMenu ? (
        <Box pl={'15px'}>
          {items.map(({ href, text }, index) => (
            <Box key={index} mb={'10px'} color="white" _hover={{ color: 'primary.600', cursor: 'pointer' }}>
              <Link
                to={href}
                as={ReactRouterLink}
                _hover={{ textDecoration: 'none' }}
                fontWeight={400}
                fontStyle={'normal'}
                fontSize={'14px'}
              >
                {text}
              </Link>
            </Box>
          ))}
        </Box>
      ) : null
    }
    </>
  );
};

export default Header;
