import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { Button, Flex, Text } from '@chakra-ui/react';
import { indexOf, lastIndexOf } from 'ramda';
import { FC, memo, useEffect, useState } from 'react';

interface PaginationProps {
  lastPage?: number;
  firstPage?: number;
  apiCall?: (page: number) => void;
  page: number;
  isLoading?: boolean;
}

const Pagination: FC<PaginationProps> = ({ firstPage = 1, lastPage = 1, apiCall, page, isLoading = false }) => {
  const [paginationList, setPaginationList] = useState<(string | number)[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(page);

  useEffect(() => {
    if (lastPage < 5) {
      setPaginationList(Array.from({ length: lastPage }, (_, i) => i + 1));
    } else {
      setPaginationList([1, 2, 'spreader', lastPage - 1, lastPage]);
    }
  }, [lastPage]);

  useEffect(() => {
    if (page) {
      setCurrentPage(page);
      formatPagination(page);
    }
  }, [page]);

  const formatPagination = (page: number) => {
    if (lastPage < 5) {
      setPaginationList(Array.from({ length: lastPage }, (_, i) => i + 1));
    } else if (firstPage === page) {
      setPaginationList([1, 2, 'spreader', lastPage - 1, lastPage]);
    } else if (lastPage === page) {
      setPaginationList([1, 2, 'spreader', lastPage - 1, lastPage]);
    } else if (lastPage - 1 === page) {
      setPaginationList([1, 2, 'spreader', page - 1, page, lastPage]);
    } else {
      const list = Array.from(new Set([page - 1, page, page + 1, 'spreader', lastPage - 1, lastPage]));
      if (list[indexOf('spreader', list) + 1] === lastPage) {
        list.splice(indexOf('spreader', list), 1);
      }
      let element1 = list[lastIndexOf('spreader', list) - 1] as number;
      let element2 = list[lastIndexOf('spreader', list) + 1];
      if (element1 + 1 === element2) {
        list.splice(lastIndexOf('spreader', list), 1);
      }
      if (page - 1 > 2) {
        list.unshift(1, 'spreader');
      } else if (page - 1 === 2) {
        list.unshift(1);
      }

      setPaginationList(list);
    }
  };

  const handleClick = (page: number) => {
    if (page === currentPage) return;
    setCurrentPage(page);
    if (apiCall) apiCall(page);
    formatPagination(page);
  };

  return (
    <Flex alignItems="center" justifyContent="center" w="full">
      <Button
        size="sm"
        colorScheme="primary"
        disabled={isLoading}
        onClick={() => handleClick(firstPage)}
        px="0.9rem"
        py="1.2rem"
        roundedEnd={0}
      >
        <ArrowLeftIcon />
      </Button>
      {paginationList.map((item, index) =>
        item === 'spreader' ? (
          <Flex alignItems="center" h="full" fontSize="2rem" background="cyan.50" px=".5rem" key={index}>
            <Text mt="-1rem" fontWeight="medium">
              ...
            </Text>
          </Flex>
        ) : (
          <Button
            size="sm"
            disabled={isLoading}
            bg={currentPage === item ? 'gray.300' : 'gray.100'}
            colorScheme="gray"
            px="0.9rem"
            py="1.2rem"
            rounded="0"
            key={index}
            onClick={() => {
              handleClick(typeof item === 'string' ? parseInt(item) : item);
            }}
          >
            {item}
          </Button>
        )
      )}
      <Button
        size="sm"
        disabled={isLoading}
        onClick={() => handleClick(lastPage)}
        colorScheme="primary"
        px="0.9rem"
        py="1.2rem"
        roundedStart={0}
      >
        <ArrowRightIcon />
      </Button>
    </Flex>
  );
};

export default memo(Pagination);
