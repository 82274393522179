import { Card } from '@chakra-ui/card';
import { Divider, Flex, Heading, Box } from '@chakra-ui/layout';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../profile/infrastructure/selector';
import { selectActiveUser } from '../../connect/infrastructure/selectors';
import SpecialtiesTagsItem from './specialties-tags-item';
import FormattedMessage from 'app/shared/components/translation/formattedMessage';
import Empty from 'app/shared/components/Empty/Empty';

interface SpecialtiesTagsProps {}

const SpecialtiesTags: FC<SpecialtiesTagsProps> = () => {
  const currentUser = useSelector(getCurrentUser);
  const activeUser = useSelector(selectActiveUser);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    if (activeUser) {
      setUser(activeUser);
    } else {
      setUser(currentUser);
    }
  }, [activeUser]);

  return (
    <Card width="100%">
      <Heading size="md" textAlign="left" padding={25}>
        <FormattedMessage translationKey={'profile.specialties.title'} />
      </Heading>
      <Divider mb="3" borderColor="gray.200" />
      <Box width="100%">
        {user && user.specialties && user.specialties.length ? (
          <Flex flexWrap="wrap">
            {user?.specialties.map((specialty: string, index: number) => (
              <SpecialtiesTagsItem keyItem={specialty || `specialty-${index}`} name={specialty} />
            ))}
          </Flex>
        ) : (
          <Empty label="profile.specialties.empty.text" />
        )}
      </Box>
    </Card>
  );
};

export default SpecialtiesTags;
