import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { EventsState } from './domain/events.state';
import { EventsListModel } from './domain/eventsList.model';
import { CurrentEventModel } from './domain/currentEvent.model';

export const initialState: EventsState = {
  loading: true,
};

const EventsSlice: Slice<EventsState> = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setComingEvents: (state, action: PayloadAction<EventsListModel[]>) => {
      state.comingEvents = action.payload;
      state.loading = false;
    },
    setPastEvents: (state, action: PayloadAction<EventsListModel[]>) => {
      state.pastEvents = action.payload;
      state.loading = false;
    },
    setCurrentEvent: (state, action: PayloadAction<CurrentEventModel>) => {
      state.currentEvent = action.payload;
      state.loading = false;
    },
  },
});

export const { setLoading, setComingEvents, setPastEvents, setCurrentEvent } = EventsSlice.actions;

const EventsReducer = EventsSlice.reducer;
export default EventsReducer;
