import { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';
import FormattedMessage from '../translation/formattedMessage';
import { FaArchive } from 'react-icons/fa';
import './Empty.scss';

interface EmptyProps {
  label: string;
}
const Empty: FC<EmptyProps> = ({ label }) => {
  return (
    <Box borderRadius="md" height="170" display="flex" justifyContent="center" alignItems="center">
      <Box className="empty-card" display="bock">
        <FaArchive style={{ fontSize: '3rem', color: '#A0AEC0' }} />
        <Text fontSize="12" style={{ color: '#A0AEC0' }}>
          <FormattedMessage translationKey={label} />
        </Text>
      </Box>
    </Box>
  );
};

export default Empty;
