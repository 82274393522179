import { Img } from '@chakra-ui/image';
import { Box, Center, Heading } from '@chakra-ui/layout';
import { FC } from 'react';
import PartyImage from '../../../../../assets/images/party.png';

interface CompleteStepperPageProps {}

const CompleteStepperPage: FC<CompleteStepperPageProps> = () => {
  return (
    <Box>
      <Heading fontSize="xl" textAlign="center">
        BEDANKT VOOR HET INVULLEN VAN JOUW PROFIEL
      </Heading>
      <Center marginY="45">
        <Img src={PartyImage} width="150px" height="150px" />
      </Center>
      <Heading fontSize="xl" textAlign="center">
        WELKOM
      </Heading>
    </Box>
  );
};

export default CompleteStepperPage;
