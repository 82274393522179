
import Notification from '../../../shared/components/notification/notification';
import { useTranslation } from 'react-i18next';
import { SendMessageRequest } from '../infrastructure/domain/request/send-message.request';
import { sendMessage } from '../infrastructure/api';

interface UsePublicApiReturn {
  postSendMessage: (id: string, params: SendMessageRequest, initialValues: any) => Promise<void>;
}

export const usePublicApi = (): UsePublicApiReturn => {
  const { t: translate } = useTranslation();

  const postSendMessage: UsePublicApiReturn['postSendMessage'] = async (id, params, initialValues) => {
    try {
      const result = await sendMessage(id, params);
      if (result) {
        initialValues()
        Notification.success(translate('NOTIFICATION.SUCCESS.MESSAGESENT'));
      }
    } catch (error: any) {
      Notification.error(translate('NOTIFICATION.ERROR.MESSAGESENT'));
    }
  };

  return { postSendMessage };
};
