import { Button } from '@chakra-ui/button';
import { Flex, Stack, Text } from '@chakra-ui/layout';
import { FC } from 'react';
import { AdressModal } from '../../profile/infrastructure/domain/AdressModal';

interface AdressNoteItemProps {
  address: AdressModal;
  keyItem: string;
}

const AdressNoteItem: FC<AdressNoteItemProps> = ({ address, keyItem }) => {
  return (
    <Flex padding={5} key={keyItem}>
      <Stack width="80%" lineHeight={0.8}>
        <Text fontSize={18} fontWeight="500" size="lg">{`${address?.addressAlias} `}</Text>
        <Text fontSize={18} fontWeight="300">
          {`${address?.street},  ${address.houseNumber}`}
        </Text>
        <Text fontSize={18} fontWeight="300">
          {`${address?.postalCode},  ${address.province}`}
        </Text>
        <Text fontSize={18} fontWeight="300">
          {`${address?.phone}`}
        </Text>
      </Stack>
    </Flex>
  );
};

export default AdressNoteItem;
