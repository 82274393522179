import { ChakraProvider } from '@chakra-ui/react';
import { VVTIV_THEME } from '../config/theme';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import store from '../store/store';
import AppRouter from './AppRouter';
import { HelmetProvider } from 'react-helmet-async';
import { NOTIFICATION_DURATION } from './shared/constants/notificationTimer';
import { QueryClient, QueryClientProvider } from 'react-query';

import 'draft-js/dist/Draft.css';
import 'react-toastify/dist/ReactToastify.css';
import '../assets/style/custom.css';

export const App = () => {
  const queryClient = new QueryClient();

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ChakraProvider theme={VVTIV_THEME}>
            <AppRouter />
          </ChakraProvider>
          <ToastContainer
            position="top-right"
            autoClose={NOTIFICATION_DURATION}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick={true}
            rtl={false}
            pauseOnHover={true}
          />
        </Provider>
      </QueryClientProvider>
    </HelmetProvider>
  );
};
