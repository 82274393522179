import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
const BroadcastContainer = lazy(() => import('./broadcast.container'));
const BroadcastList = lazy(() => import('./views/broadcastList/broadcastList'));
const BroadcastCreate = lazy(() => import('./views/broadcastCreate/broadcastCreate'));

export default [
  <Route path="broadcast" element={<BroadcastContainer />}>
    <Route path="" element={<BroadcastList />} />
    <Route path="create" element={<BroadcastCreate />} />
  </Route>,
];
