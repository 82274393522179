import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

const ProfileEditCommunication = lazy(() => import('./profileEditCommunication/profileEditCommunication'));
const ProfileEditEducation = lazy(() => import('./profileEditEducation/profileEditEducation'));
const ProfileEditProfessionalDetails = lazy(() => import('./profileEditProfessionalDetails/profileEditProfessionalDetails'));
const ProfileEditBasicInformation = lazy(() => import('./profileEditBasicInformation/profileEditBasicInformation'));
const ProfileEditContainer = lazy(() => import('./profileEdit.container'));

export default [
  <Route path="edit" element={<ProfileEditContainer />}>
    <Route path="information" element={<ProfileEditBasicInformation />} />
    <Route path="professional" element={<ProfileEditProfessionalDetails />} />
    <Route path="education" element={<ProfileEditEducation />} />
    <Route path="communication" element={<ProfileEditCommunication />} />
  </Route>,
];
