import React, { lazy } from 'react';
import PrivateContainer from './private.container';
import { Route } from 'react-router-dom';
import eventRoutes from './event/routes';
import broadcastRoutes from './broadcast/routes';
import profileRoutes from './profile/routes';
import documentsRoutes from './Documents/routes';
import chatRoutes from './chat/routes';
import connectRoutes from './connect/routes';
export const Dashboard = lazy(() => import('./dashboard/dashboard.container'));
export const ProfileShow = lazy(() => import('./profile/views/profileShow/profileShow.container'));

export default [
  <Route path="/private" element={<PrivateContainer />}>
    <Route path="" element={<Dashboard />} />
    {eventRoutes}
    {broadcastRoutes}
    {profileRoutes}
    {documentsRoutes}
    {chatRoutes}
    {connectRoutes}
  </Route>,
];
