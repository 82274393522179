import { Box, BoxProps, Icon, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
interface NoDataFoundProps{
title?:string
containerStyle?:BoxProps,
minH?:string
}
const NoDataFound: FC<NoDataFoundProps> = ({ title = 'common.nodatafound', containerStyle, minH = '30rem' }) => {
  const { t } = useTranslation()

  return (
    <Box
      minH={minH}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap="1rem"
      {...containerStyle}
    >
      <Icon xmlns="http://www.w3.org/2000/svg" width="180.951" height="176.332" viewBox="0 0 180.951 176.332">
        <g id="No_Files" data-name="No Files" transform="translate(-96.341 -74.958)">
          <path
            id="Path_7768"
            data-name="Path 7768"
            d="M71.5,0A71.5,71.5,0,1,1,0,71.5,71.5,71.5,0,0,1,71.5,0Z"
            transform="translate(96.341 74.958)"
            fill="#f7f7f8"
          />
          <rect
            id="Rectangle_22518"
            data-name="Rectangle 22518"
            width="70"
            height="98"
            rx="7"
            transform="translate(139.792 145.001) rotate(2.5)"
            fill="#eee"
            stroke="#929292"
            strokeWidth="2"
          />
          <path
            id="Path_7769"
            data-name="Path 7769"
            d="M9,0H65a9,9,0,0,1,9,9V93a9,9,0,0,1-9,9H9a9,9,0,0,1-9-9V9A9,9,0,0,1,9,0Z"
            transform="translate(98.177 139.138) rotate(-7.5)"
            fill="#fafafa"
            stroke="#929292"
            strokeWidth="2"
          />
          <path
            id="Path_7766"
            data-name="Path 7766"
            d="M100.343,147.932a8,8,0,0,1,6.888-8.976l55.521-7.31a8,8,0,0,1,8.975,6.888l1.958,14.871L102.3,162.8Z"
            fill="#eee"
          />
          <rect
            id="Rectangle_22520"
            data-name="Rectangle 22520"
            width="3"
            height="10"
            rx="1.5"
            transform="translate(186.209 133.602) rotate(82.5)"
            fill="#929292"
          />
          <rect
            id="Rectangle_22521"
            data-name="Rectangle 22521"
            width="3"
            height="10"
            rx="1.5"
            transform="translate(158.811 117.035) rotate(-7.5)"
            fill="#929292"
          />
          <rect
            id="Rectangle_22522"
            data-name="Rectangle 22522"
            width="3"
            height="10"
            rx="1.5"
            transform="matrix(0.793, 0.609, -0.609, 0.793, 176.389, 119.764)"
            fill="#929292"
          />
          <rect
            id="Rectangle_22523"
            data-name="Rectangle 22523"
            width="58"
            height="4"
            rx="2"
            transform="translate(109.764 165.854) rotate(-7.5)"
            fill="#2c2c2c"
          />
          <rect
            id="Rectangle_22524"
            data-name="Rectangle 22524"
            width="58"
            height="4"
            rx="2"
            transform="translate(112.374 185.683) rotate(-7.5)"
            fill="#2c2c2c"
          />
          <rect
            id="Rectangle_22525"
            data-name="Rectangle 22525"
            width="58"
            height="4"
            rx="2"
            transform="translate(114.985 205.512) rotate(-7.5)"
            fill="#2c2c2c"
          />
          <rect
            id="Rectangle_22526"
            data-name="Rectangle 22526"
            width="21"
            height="4"
            rx="2"
            transform="translate(110.808 173.786) rotate(-7.5)"
            fill="#2c2c2c"
            opacity="0.29"
          />
          <rect
            id="Rectangle_22527"
            data-name="Rectangle 22527"
            width="21"
            height="4"
            rx="2"
            transform="translate(113.418 193.615) rotate(-7.5)"
            fill="#2c2c2c"
            opacity="0.29"
          />
          <rect
            id="Rectangle_22528"
            data-name="Rectangle 22528"
            width="21"
            height="4"
            rx="2"
            transform="translate(116.029 213.444) rotate(-7.5)"
            fill="#2c2c2c"
            opacity="0.29"
          />
          <circle
            id="Ellipse_1020"
            data-name="Ellipse 1020"
            cx="33.094"
            cy="33.094"
            r="33.094"
            transform="translate(188 160)"
            fill="#f1f1f1"
            stroke="#929292"
            strokeWidth="2"
          />
          <circle
            id="Ellipse_1021"
            data-name="Ellipse 1021"
            cx="23.353"
            cy="23.353"
            r="23.353"
            transform="translate(197.741 169.741)"
            fill="#fffeff"
            stroke="#929292"
            strokeWidth="2"
          />
          <path
            id="Path_7767"
            data-name="Path 7767"
            d="M221.094,175.19a.631.631,0,0,0-.647-.636,18.552,18.552,0,0,0-17.81,16.669.63.63,0,0,0,.592.688h0a.667.667,0,0,0,.7-.6,17.256,17.256,0,0,1,16.517-15.458.667.667,0,0,0,.647-.66Z"
            fill="#c4c4c4"
          />
          <rect
            id="Rectangle_22529"
            data-name="Rectangle 22529"
            width="11.533"
            height="33.775"
            rx="5.766"
            transform="translate(243.841 225.993) rotate(-45)"
            fill="#f1f1f1"
            stroke="#929292"
            strokeWidth="2"
          />
          <rect
            id="Rectangle_22530"
            data-name="Rectangle 22530"
            width="1.548"
            height="12.977"
            rx="0.774"
            transform="translate(258.678 231.479) rotate(-45)"
            fill="#fff"
          />
          <rect
            id="Rectangle_22531"
            data-name="Rectangle 22531"
            width="1.589"
            height="6.355"
            transform="translate(243.001 218.123) rotate(-45)"
            fill="#999"
          />
        </g>
      </Icon>

      <Text fontSize="xl" fontWeight="semibold">
        {t(title)}
      </Text>
    </Box>
  )
}

export default NoDataFound
