import React, { FC } from 'react';
import { Container, ContainerProps } from '@chakra-ui/react';

const BaseContainer: FC<ContainerProps> = ({ children, maxW = '8xl', centerContent = false, ...props }) => {
  return (
    <Container maxW={maxW} mb={'2rem'} centerContent={centerContent} {...props}>
      {children}
    </Container>
  );
};

export default BaseContainer;
