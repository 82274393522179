import React, { FC } from 'react';
import { FormControl, FormErrorMessage, Input, InputGroup } from '@chakra-ui/react';
import { FieldHookConfig, useField } from 'formik';
import FormattedMessage from '../../translation/formattedMessage';

// @ts-ignore
export interface InputDateProps extends FieldHookConfig<any> {
  name: string;
  type?: 'date' | 'datetime-local';
  onChange?: (value: string) => void;
  minDate?: string;
}

export const InputDate: FC<InputDateProps> = ({ type = 'datetime-local', onChange, minDate, ...props }) => {
  const [field, meta] = useField(props.name);
  return (
    <FormControl isInvalid={meta.touched && !!meta.error}>
      <InputGroup>
        <Input
          {...field}
          {...props}
          size="md"
          type={type}
          min={minDate}
          onChange={(e) => onChange && onChange(e.target.value)}
        />
      </InputGroup>
      <FormErrorMessage>
        <FormattedMessage translationKey={meta.error as string} />
      </FormErrorMessage>
    </FormControl>
  );
};
