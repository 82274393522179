import { Box, Text } from '@chakra-ui/layout';
import { FC } from 'react';
import './specialties-tags.scss';

interface SpecialtiesTagsItemProps {
  name: string;
  keyItem: string;
}

const SpecialtiesTagsItem: FC<SpecialtiesTagsItemProps> = ({ name, keyItem }) => {
  return (
    <Box
      key={keyItem}
      cursor="pointer"
      borderRadius="md"
      paddingY="1"
      paddingX="3"
      marginBottom="4"
      marginLeft="4"
      color="white"
      bgColor="primary.500"
    >
      <Text className="tag-name">{name}</Text>
    </Box>
  );
};

export default SpecialtiesTagsItem;
