import { chakra, Flex, forwardRef, ImageProps, keyframes, usePrefersReducedMotion } from '@chakra-ui/react';
import logo from '../../../../assets/images/logo.svg';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const Spinner = forwardRef<ImageProps, 'img'>((props, ref) => {
  const prefersReducedMotion = usePrefersReducedMotion();

  const animation = prefersReducedMotion ? undefined : `${spin} infinite 3s linear`;

  return (
    <></>
    // Commented due to client request ( maybe will uncommented later )
    // <Flex
    //   flexDirection="column"
    //   width="100wh"
    //   height="100vh"
    //   backgroundColor="white.200"
    //   alignItems="center"
    //   justifyContent={'center'}
    // >
    //   <chakra.img animation={animation} src={logo} ref={ref} {...props} />
    // </Flex>
  );
});
