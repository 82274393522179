import {
  cancelEventApi,
  createEventApi,
  getEventByIdApi,
  getEventsListApi,
  participateEventApi,
} from '../infrastructure/api';
import { useDispatch } from 'react-redux';
import { setComingEvents, setCurrentEvent, setLoading, setPastEvents } from '../infrastructure/store';
import { GetEventsListRequest } from '../infrastructure/domain/request/getEventsList.request';
import { EventsStatus } from '../../../../shared/enums/events/eventsStatus';
import { CreateEventRequest } from '../infrastructure/domain/request/createEvent.request';
import Notification from '../../../../shared/components/notification/notification';
import { useTranslation } from 'react-i18next';

interface UseEventsApiReturn {
  // getEventsList: (params: GetEventsListRequest) => Promise<void>;
  getEventById: (id: string) => Promise<void>;
  createEvent: (params: CreateEventRequest) => Promise<boolean>;
  participateEvent: (id: string) => Promise<boolean | any>;
  cancelEvent: (id: string) => Promise<boolean>;
  getEventByType: (params: GetEventsListRequest) => Promise<void>;
}

export const useEventsApi = (): UseEventsApiReturn => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const getEventByType: UseEventsApiReturn['getEventByType'] = async (params) => {        
    dispatch(setLoading(true));
    const EventResult = await getEventsListApi(params);    
    if (EventResult) {
      dispatch(setComingEvents(EventResult));
    }
    dispatch(setLoading(false));
  };

  const getEventById: UseEventsApiReturn['getEventById'] = async (id) => {
    dispatch(setLoading(true));
    const result = await getEventByIdApi(id);
    dispatch(setCurrentEvent(result));
  };

  const createEvent: UseEventsApiReturn['createEvent'] = async (params) => {
    const result = await createEventApi(params);
    dispatch(setCurrentEvent(result));
    if (result.startDate) {
      return true;
    } else {
      return false;
    }
  };

  const participateEvent: UseEventsApiReturn['participateEvent'] = async (id) => {
    try {
      const result = await participateEventApi(id);
      if (result) {
        if (result?.refTx === 'FREE') {
          Notification.success(translate('event.notification.congratsParticipation'));
        }
        await getEventById(id);
        return result;
      }
      return false;
    } catch (error) {
      Notification.error('Er is iets mis gegaan');
      return false;
    }
  };

  const cancelEvent: UseEventsApiReturn['cancelEvent'] = async (id) => {
    try {
      const result = await cancelEventApi(id);
      if (result) {
        Notification.success(translate('event.notification.cancel'));
        return true;
      }
      return false;
    } catch (error) {
      Notification.error('Er is iets mis gegaan');
      return false;
    }
  };

  return { getEventById, createEvent, participateEvent, cancelEvent, getEventByType };
};
