import React, { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { authenticationFeature } from './features/authentification';
import { privateFeature } from './features/private';

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Suspense>
        <Routes>
          {authenticationFeature.routes}
          {privateFeature.routes}
          <Route path="/" element={<Navigate replace to="/login" />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
