import { Card } from '@chakra-ui/card';
import { Divider, Heading, Text } from '@chakra-ui/layout';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FormattedMessage from '../../../../shared/components/translation/formattedMessage';
import { getCurrentUser } from '../../profile/infrastructure/selector';
import { selectActiveUser } from '../../connect/infrastructure/selectors';
import Empty from 'app/shared/components/Empty/Empty';

interface PersonalIntroductionProps {}

const PersonalIntroduction: FC<PersonalIntroductionProps> = () => {
  const currentUser = useSelector(getCurrentUser);
  const activeUser = useSelector(selectActiveUser);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    if (activeUser) {
      setUser(activeUser);
    } else {
      setUser(currentUser);
    }
  }, [activeUser]);

  return (
    <Card width="100%">
      <Heading size="md" textAlign="left" padding={25}>
        <FormattedMessage translationKey={'profile.bio.title'} />
      </Heading>
      <Divider borderColor="gray.200" />
      {user?.aboutMe ? (
        <Text
          paddingY="15"
          paddingX="5"
          margin="5"
          dangerouslySetInnerHTML={{
            __html: user?.aboutMe,
          }}
        />
      ) : (
        <Empty label="profile.bio.empty.text" />
      )}
    </Card>
  );
};

export default PersonalIntroduction;
