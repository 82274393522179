import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import nl from '../translations/nl.json';
import fr from '../translations/fr.json';
import en from '../translations/en.json';

const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  cacqsdhes: ['localStorage'],
};

const resources = {
  en: {
    translation: en,
  },
  nl: {
    translation: nl,
  },
  fr: {
    translation: fr,
  },
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  lng: 'nl',
  fallbackLng: 'nl',
  detection: DETECTION_OPTIONS,
});

export default i18n;
