import Client from '../../../../shared/utils/client';
import { GetUserMeResponse } from './domain/response/getUserMe.response';
import { PostChargeRequest } from './domain/request/postChargeRequest';
import { PatchUserMeRequest } from './domain/request/patchUserMe.request';
import { GetChargeResponse } from './domain/response/ChargeResponse';
import { EducationModel } from './domain/education.model';
import { PostEducationRequest } from './domain/request/postEducation.request';
import { AdressModal } from './domain/AdressModal';

const userPrefix = 'user';
const chargePrefix = 'charge';
const educationPrefix = 'education';
const specialtiesPrefix = 'specialties';
const addressPrefix = 'address';

export const getUserMeApi = (): Promise<GetUserMeResponse> => Client.get(`${userPrefix}/me`);

export const patchUserMeApi = (body: PatchUserMeRequest): Promise<GetUserMeResponse> =>
  Client.patch(`${userPrefix}`, body);

export const postChargeApi = (body: PostChargeRequest): Promise<GetChargeResponse> =>
  Client.post(`${chargePrefix}`, body);

export const patchChargeApi = (id: string, body: PostChargeRequest): Promise<GetChargeResponse> =>
  Client.patch(`${chargePrefix}/${id}`, body);

export const deleteChargeApi = (id: string): Promise<GetChargeResponse> => Client.delete(`${chargePrefix}/${id}`);

export const getChargeApi = (): Promise<Array<GetChargeResponse>> => Client.get(`${chargePrefix}`);
export const postEducationApi = (body: PostEducationRequest): Promise<Array<EducationModel>> =>
  Client.post(`${educationPrefix}`, body);
export const getEducationApi = (): Promise<Array<EducationModel>> => Client.get(`${educationPrefix}`);
export const getEducationsByUserApi = (id: string): Promise<any> => Client.get(`${educationPrefix}/byuser/${id}`);

export const deleteEducationApi = (id: string): Promise<EducationModel> => Client.delete(`${educationPrefix}/${id}`);
export const searchSpecialityApi = (searchText: string): Promise<string[]> =>
  Client.get(`${specialtiesPrefix}/search/${searchText}`);

export const editEducationApi = (id: string, body: PostEducationRequest): Promise<Array<EducationModel>> =>
  Client.patch(`${educationPrefix}/${id}`, body);

export const getAddressApi = (): Promise<AdressModal> => Client.get(`${addressPrefix}`);
export const addAddressApi = (body: AdressModal): Promise<AdressModal> => Client.post(`${addressPrefix}`, body);
export const deleteAddressApi = (id: string): Promise<AdressModal> => Client.delete(`${addressPrefix}/${id}`);
export const patchAddressApi = (id: string, body: AdressModal): Promise<AdressModal> =>
  Client.patch(`${addressPrefix}/${id}`, body);

export const preferredAddressApi = (): Promise<AdressModal> => Client.get(`${addressPrefix}/preferredAddress`)
export const parchPreferredAddressApi = (id: string): Promise<AdressModal> => Client.patch(`${addressPrefix}/preferredAddress/${id}`)
