import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PrivateState {
  title: string;
}

export const initialState: PrivateState = {
  title: 'Persoonlijke account',
};

const PrivateSlice = createSlice({
  name: 'private',
  initialState,
  reducers: {
    setPrivateTitle: (state: PrivateState, action: PayloadAction<any>) => {
      state.title = action.payload.title;
    },
  },
});

export const { setPrivateTitle } = PrivateSlice.actions;

const PrivateReducer = PrivateSlice.reducer;
export default PrivateReducer;
