import { IPaginationConfig } from '../../../../shared/types/pagination-config.type';
import Client from '../../../../shared/utils/client';
import _ from 'lodash';

const userPrefix = 'public/user';
const connectionPrefix = 'connection';

export const getAllUsersApi = (paginationConfig: IPaginationConfig, isPublic: boolean): Promise<any> => {
  const pagination = `?page=${paginationConfig.page}&limit=${paginationConfig.limit}`;
  return Client.get(`public/network${pagination}`);
};
export const getMyNetworkUsersApi = (paginationConfig: IPaginationConfig, query: any = {}): Promise<any> => {
  for (let item in query) {
    if (!query[item]) {
      delete query[item];
    }
  }
  const newQuery = {
    ...query,
    ...paginationConfig,
    ...(query.specialties && query.specialties.length ? { specialities: query.specialties } : {}),
  };
  delete newQuery.specialties;

  const params = new URLSearchParams(newQuery).toString();
  return Client.get(`user/my-network?${params}`);
};
export const getUserByIdApi = (id: string): Promise<any> => Client.get(`${userPrefix}/${id}`);
export const filterUsersApi = (query: any, paginationConfig: IPaginationConfig): Promise<any> => {
  for (let item in query) {
    if (!query[item]) {
      delete query[item];
    }
  }
  const newQuery = {
    ...query,
    ...paginationConfig,
    ...(query.specialties && query.specialties.length ? { specialities: query.specialties } : {}),
  };
  delete newQuery.specialties;

  const params = new URLSearchParams(newQuery).toString();
  return Client.get(`public/network?${params}`);
};

export const getPendingInvitationsApi = (): Promise<any> => {
  return Client.get(`${connectionPrefix}/pending`);
};
export const getDeclinedInvitationsApi = (): Promise<any> => {
  return Client.get(`${connectionPrefix}/declined`);
};

export const getPendingInvitationsAsTargetApi = (): Promise<any> => {
  return Client.get(`${connectionPrefix}/pending/target`);
};
export const getDeclinedInvitationsAsTargetApi = (): Promise<any> => {
  return Client.get(`${connectionPrefix}/declined/target`);
};

export const acceptInvitationApi = (invitationId: string): Promise<any> => {
  return Client.patch(`${connectionPrefix}/accept/${invitationId}`);
};

export const sendInvitationApi = (userId: string): Promise<any> => {
  return Client.post(`${connectionPrefix}/${userId}`);
};

export const declineInvitationApi = (invitationId: string): Promise<any> => {
  return Client.patch(`${connectionPrefix}/decline/${invitationId}`);
};

export const cancelInvitationApi = (invitationId: string): Promise<any> => {
  return Client.delete(`${connectionPrefix}/cancel/${invitationId}`);
};

export const checkConnectionApi = (userId: string): Promise<any> => {
  return Client.get(`${connectionPrefix}/checkconnection/${userId}`);
};

export const deleteConnectionApi = (userId: string): Promise<any> => {
  return Client.delete(`${connectionPrefix}/delete/${userId}`);
};
