import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { BroadcastState } from './domain/broadcast.state';
import { BroadcastModel } from './domain/broadcast.model';

export const initialState: BroadcastState = {
  loading: true,
};

const BroadcastSlice: Slice<BroadcastState> = createSlice({
  name: 'broadcast',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setBroadcast: (state, action: PayloadAction<BroadcastModel[]>) => {
      state.broadcast = action.payload;
    },
  },
});

export const { setLoading, setBroadcast } = BroadcastSlice.actions;

const BroadcastReducer = BroadcastSlice.reducer;
export default BroadcastReducer;
