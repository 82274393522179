import { FC } from 'react';
import { Box, BoxProps, Card, CardBody, Flex, Text } from '@chakra-ui/react';
import FormattedMessage from '../../../../../shared/components/translation/formattedMessage';

interface EditProfileContentProps extends BoxProps {
  title: string;
  marginBottom?: number;
  marginTop?: number;
  headerLeft?: JSX.Element;
}

const EditProfileContent: FC<EditProfileContentProps> = ({ title, children, marginBottom, marginTop, headerLeft, ...props }) => {
  return (
    <Card
      boxShadow={'0px 0px 32px rgba(136, 152, 170, 0.15)'}
      borderRadius={'6px'}
      minW={{ base: '100%' }}
      {...props}
      marginBottom={marginBottom || 'inherit'}
      marginTop={marginTop || 'inherit'}
    >
      <CardBody>
        <Flex justifyContent={'space-between'}>
          <Text as='b' fontSize='md' mb='2.5'>
            <FormattedMessage translationKey={title} />
          </Text>
          {headerLeft}
        </Flex>
        <Box mt='2.5'>{children}</Box>
      </CardBody>
    </Card>
  );
};

export default EditProfileContent;
