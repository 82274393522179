import React, { FC } from 'react';
import { Flex, Text, ModalBody, ModalContent, Button, Img } from '@chakra-ui/react';
import SuccesIcon from '../../../../../assets/images/success.png';
import { useDispatch } from 'react-redux';
import { setFirstLogin } from '../../../authentification/infrastructure/store';
interface ModalSuccesProps {
  closeModal: () => void;
}
const ModalSucces: FC<ModalSuccesProps> = ({ closeModal }) => {
  const dispatch = useDispatch();
  return (
    <ModalContent>
      <ModalBody>
        <Flex alignItems="center" flexDir="column" gap=".5rem" p="1rem" justifyContent="center">
          <Text>BEDANKT VOOR HET INVULLEN VAN JOUW PROFIEL</Text>
          <Img src={SuccesIcon} w="8rem" mt="2rem" />

          <Text fontSize="2xl" fontWeight="bold" mt="2rem">
            Welkom!
          </Text>
          <Button
            mt="2rem"
            onClick={() => {
              closeModal();
              dispatch(setFirstLogin(false));
              localStorage.removeItem('firstLogin');
            }}
          >
            Close
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  );
};

export default ModalSucces;
