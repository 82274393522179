import { useDisclosure } from '@chakra-ui/hooks';
import { Modal, ModalOverlay } from '@chakra-ui/modal';
import { FC, useState } from 'react';
import { setFirstLogin } from '../../../shared/utils/isFirstLogin';
import Introduction from './introduction/introduction';
import { isMobile } from 'react-device-detect';
import SetupProfileForm from './setupProfileForm/setupProfileForm';
import ModalSucces from './steps/ModalSucces';

interface ModalStepperUserInfoProps {
  defaultIsOpen: boolean;
}

const SetupProfileModal: FC<ModalStepperUserInfoProps> = ({ defaultIsOpen }) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: defaultIsOpen });
  const [showStepper, setShowStepper] = useState(false);
  const [showModalSucces, setShowModalSucces] = useState(false);
  const onCloseModal = () => {
    setFirstLogin('false');
    onClose();
  };
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onCloseModal}
      size={{ base: 'full', lg: '5xl' }}
      isCentered
    >
      <ModalOverlay bg="transparent" backdropFilter="blur(10px)" />
      {!showStepper && <Introduction onClick={() => setShowStepper(true)} />}
      {showStepper && !showModalSucces && (
        <SetupProfileForm
          onComplete={() => {
            setShowModalSucces(true);
          }}
        />
      )}
      {showModalSucces && <ModalSucces closeModal={onCloseModal} />}
    </Modal>
  );
};

export default SetupProfileModal;
