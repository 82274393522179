import { lazy } from 'react';
import { Route } from 'react-router-dom';
import UserProfile from './views/user-profile/user-profile';

const ExploreUsers = lazy(() => import('./views/explore/explore'));

export default [
  <Route path="connect">
    <Route path="" element={<ExploreUsers />} />
    <Route path="profile/:id" element={<UserProfile />} />
  </Route>,
];
