import { useDispatch } from 'react-redux';
import { deleteChargeApi, getChargeApi, patchChargeApi, postChargeApi } from '../infrastructure/api';
import { PostChargeRequest } from '../infrastructure/domain/request/postChargeRequest';
import { setCharge, addCharge, editCharge, removeCharge } from '../infrastructure/store';

interface UseChargeApiReturn {
  getCharge: () => Promise<void>;
  postCharge: (body: PostChargeRequest) => Promise<void>;
  patchCharge: (id: string, body: PostChargeRequest) => Promise<void>;
  deleteCharge: (id: string) => Promise<void>;
}

export const useChargeApi = (): UseChargeApiReturn => {
  const dispatch = useDispatch();

  const getCharge: UseChargeApiReturn['getCharge'] = async () => {
    const result = await getChargeApi();
    dispatch(setCharge(result));
  };

  const postCharge: UseChargeApiReturn['postCharge'] = async (body) => {
    const result = await postChargeApi(body);
    dispatch(addCharge(result));
  };

  const patchCharge: UseChargeApiReturn['patchCharge'] = async (id, body) => {
    const result = await patchChargeApi(id, body);
    dispatch(editCharge(result));
  };

  const deleteCharge: UseChargeApiReturn['deleteCharge'] = async (id) => {
    const result = await deleteChargeApi(id);
    dispatch(removeCharge(id));
  };

  return { getCharge, postCharge, patchCharge, deleteCharge };
};
