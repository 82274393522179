import { FC } from 'react';
import { Card } from '@chakra-ui/card';
import { Divider, Heading, Box } from '@chakra-ui/layout';
import { useSelector } from 'react-redux';
import FormattedMessage from 'app/shared/components/translation/formattedMessage';
import { getAddressList, getLoading } from '../../profile/infrastructure/selector';
import AdressNoteItem from './adress-note-item';
import Empty from 'app/shared/components/Empty/Empty';

interface AdressListProps {}

const AdressList: FC<AdressListProps> = () => {
  const addresses = useSelector(getAddressList);
  const isLoading = useSelector(getLoading);
  return (
    <Card width="100%">
      <Heading size="md" textAlign="left" padding={25}>
        <FormattedMessage translationKey={'profile.adress.title'} />
      </Heading>
      <Divider borderColor="gray.200" />
      <Box width="100%">
        {addresses &&
          addresses.length !== 0 &&
          addresses.map((item, index) => <AdressNoteItem address={item} keyItem={item.id || `adress-${index}`} />)}
        {addresses && addresses.length === 0 && <Empty label="profile.adress.empty.text" />}
      </Box>
    </Card>
  );
};

export default AdressList;
