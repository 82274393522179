import * as Yup from 'yup';

export const StepperUserInfoSchema = Yup.object().shape({
  remote: Yup.string(),
  availability: Yup.string(),
  seo: Yup.string(),
  seniority: Yup.string(),
  type: Yup.number(),
  public: Yup.boolean(),
  vatNumber: Yup.string(),
  company: Yup.string(),
  street: Yup.string(),
  houseNumber: Yup.string(),
  postalCode: Yup.string(),
  city: Yup.string(),
  country: Yup.string(),
  linkedin: Yup.string(),
  website: Yup.string(),
});
