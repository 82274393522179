import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { GetDocumentsResponse } from "./domain/response/getDocuments.response";
export interface DocumentState{
    loading:boolean,
    documents?:any[]
}
export const initialState: DocumentState = {
    loading: false,
    documents:[]
  };
  
  const DocumentsSlice: Slice<DocumentState> = createSlice({
    name: 'document',
    initialState,
    reducers: {
        setDocuments: (state, action: PayloadAction<GetDocumentsResponse[]>) => {
            state.documents = action.payload;
          },
    }
})
export const {setDocuments}=DocumentsSlice.actions
 const DocumentReducer=DocumentsSlice.reducer
export default DocumentReducer;
