import React from 'react';
import { toast } from 'react-toastify';

export default class Notification {
  static success(content: string) {
    toast.success(content);
  }

  static warning(content: string) {
    toast.warning(content);
  }

  static error(content: string) {
    toast.error(content);
  }

  static info(content: string) {
    toast.info(content);
  }
}
