import { Button } from '@chakra-ui/button';
import { Box, Heading, Text } from '@chakra-ui/layout';
import { FunctionComponent, MouseEventHandler } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../profile/infrastructure/selector';
import FormattedMessage from '../../../../shared/components/translation/formattedMessage';
import { ModalContent } from '@chakra-ui/react';
import { ModalBody, ModalFooter } from '@chakra-ui/modal';

interface IntroductionProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
}
const Introduction: FunctionComponent<IntroductionProps> = ({ onClick }) => {
  const currentUser = useSelector(getCurrentUser);
  return (
    <ModalContent>
      <ModalBody>
        <Box paddingX="25" paddingY="10">
          <Text>
            <FormattedMessage
              translationKey="setupProfileModal.introduction.userName.text"
              value={{ firstName: currentUser?.firstName }}
            />
          </Text>
          <Heading size="md">
            <FormattedMessage translationKey="setupProfileModal.introduction.title.text" />
          </Heading>
          <Text marginTop="10">
            <FormattedMessage translationKey="setupProfileModal.introduction.description.text" />
          </Text>
        </Box>
        <ModalFooter justifyContent="start">
          <Button marginTop="25" colorScheme="primary" onClick={onClick}>
            <FormattedMessage translationKey="setupProfileModal.introduction.button.start.text" />
          </Button>
        </ModalFooter>
      </ModalBody>
    </ModalContent>
  );
};

export default Introduction;
