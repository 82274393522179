import { FunctionComponent } from 'react';
import { FormControl, FormErrorMessage, FormLabel, Switch, SwitchProps } from '@chakra-ui/react';
import { FieldHookConfig, useField } from 'formik';

// @ts-ignore
interface ISwitchFieldProps extends FieldHookConfig<SwitchProps> {
  name: string;
  label?: string;
  isBold?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
  size?:"sm"|"md"|"lg";
  className?:string
}

const SwitchInput: FunctionComponent<ISwitchFieldProps> = ({className, size="md",isBold, name, label, onChange, isChecked = false }) => {
  const [field, meta] = useField({ name });
  return (
    <FormControl display="flex" alignItems="center" isInvalid={meta.touched && !!meta.error} className={className}>
      {label && <FormLabel htmlFor={`switch_${name}`} mb='0' fontWeight={isBold ? 'bold' : 400}>
        {label}
      </FormLabel>}
      <Switch id={`switch_${name}`} onChange={onChange} name={name} colorScheme="primary" size={size} isChecked={isChecked} />

      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export default SwitchInput;
