import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { HttpStatus } from '../enums/httpStatus';
import Notification from '../components/notification/notification';
import { BASE_URL } from '../../../config';

export const baseURL = `${BASE_URL}/api`;

const axiosInstance = axios.create({
  baseURL,
});

const onFulfilled = (res: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => {
  if (res.config.url === 'auth/login' && res.status === HttpStatus.CREATED) {
    localStorage.setItem('token', res.data.token);
    if (res.data.user.firstLogin) {
      localStorage.setItem('firstLogin', 'true');
    }
  }
  return res.data;
};

const onRejected = (error: AxiosError): AxiosResponse | Promise<AxiosResponse> => {
  if (error.response?.status === HttpStatus.UNAUTHORIZED) {
    localStorage.clear();
    window.open('/login');
  }

  if (error.response?.status === HttpStatus.INTERNAL_SERVER) {
    Notification.error('Probleem is opgetreden');
  }

  if (error.response?.status === HttpStatus.CONFLICT) {
    // @ts-ignore
    Notification.error(error?.response?.data?.message || '');
  }

  return Promise.reject(error);
};

const onFulfilledRequest = (axiosConfig: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const config: InternalAxiosRequestConfig = {
    ...axiosConfig,
  };

  const token = localStorage.getItem('token');
  if (token) {
    // @ts-ignore
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
};

axiosInstance.interceptors.response.use(onFulfilled, onRejected);
axiosInstance.interceptors.request.use(onFulfilledRequest, (error) => Promise.reject(error));

export default axiosInstance;
