import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Stack, Spacer, Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import SkeletonVVTIV from '../../../shared/components/skeleton/skeleton';
import { SkeletonShape } from '../../../shared/enums/skeletonShape';
import LanguagesTags from '../../private/components/languages-tags/languages-tags';
import PersonalIntroduction from '../../private/components/personal-introduction/personal-introduction';
import ProfileInfo from '../../private/components/profile-info/profile-info';
import SpecialtiesTags from '../../private/components/specialties-tags/specialties-tags';
import Speciality from '../../private/components/speciality/speciality';
import { useConnectApi } from '../../private/connect/hooks/useConnectApi';
import { selectActiveUser, selectConnectLoading } from '../../private/connect/infrastructure/selectors';
import { setActiveUser } from '../../private/connect/infrastructure/store';
import { setPrivateTitle } from '../../private/infrastructure/store';
import ProfileHeader from './profile-header/ProfileHeaderPublic';
import MessageSender from './message-sender/MessageSender';
import AdressList from 'app/features/private/components/personal-notes/adress-List';
import Notification from 'app/shared/components/notification/notification';
import { isLoggedInUser } from 'app/shared/utils/isLoggedInUser';
import Empty from 'app/shared/components/Empty/Empty';
import './profileView.scss'

interface ProfileViewProps { }

const ProfileView: FC<ProfileViewProps> = ({ }) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getUserById } = useConnectApi();
  const { id } = useParams<{ id: string }>();
  const isLoading = useSelector(selectConnectLoading);
  const [isEmpty, setIsEmpty] = useState(false);
  const activeUser = useSelector(selectActiveUser);
  const screenWidth = window.screen.width;

  useEffect(() => {
    dispatch(setPrivateTitle({ title: 'ProfilePublic' }));
    if (id) {
      getUserById(id).then(
        (res: any) => {
          if (isLoggedInUser()) {
            return navigate(`/private/connect/profile/${id}`);
          } else {
            if (!res.isPublic) {
              Notification.error(translate('profile.settings.private.warning'));
              navigate('/login');
            }
          }
        },
        (err) => {
          Notification.error(translate('profile.settings.errorMessage'));
          setIsEmpty(true);
        }
      );
    } else {
      Notification.error(translate('profile.settings.profile_not_found.warning'));
      navigate('/login');
    }

    return () => {
      dispatch(setActiveUser(null));
      dispatch(setPrivateTitle({ title: '' }));
    };
  }, []);

  return isLoading ? (
    <Skeleton />
  ) : (
    <Box width="100%" className='public-profil-details'>
      {isEmpty ? (
        <Empty label="common.nodatafound" />
      ) : (
        <>
          <ProfileHeader />
          <Flex px="1rem" justifyContent="flex-start">
            <Stack width="65%" margin="5">
              <SpecialtiesTags />
              <PersonalIntroduction />
              <Speciality isPublic userId={id} />
              <LanguagesTags />
              {
                screenWidth <= 768 && (
                  <Stack >
                    <ProfileInfo />
                    {
                      activeUser && activeUser.role !== 'STUDENT' ? (
                        <AdressList />
                      ) : null
                    }
                  </Stack>
                )
              }
              <MessageSender id={id} />
            </Stack>
            <Spacer />
            <Spacer />
            {
              screenWidth > 768 && (
                <Stack width="35%" margin="5">
                  <ProfileInfo />
                  {
                    activeUser && activeUser.role !== 'STUDENT' ? (
                      <AdressList />
                    ) : null
                  }
                </Stack>
              )
            }
          </Flex>
        </>
      )}
    </Box>
  );
};

const Skeleton = () => {
  return (
    <Box width="100%">
      <Flex justifyContent="flex-start">
        <Stack width="65%" margin="5">
          <SkeletonVVTIV type={SkeletonShape.TEXT} noOfLines={4} />
          <Spacer />
          <SkeletonVVTIV type={SkeletonShape.TEXT} noOfLines={4} />
          <Spacer />
          <SkeletonVVTIV type={SkeletonShape.TEXT} noOfLines={1} />
          <SkeletonVVTIV type={SkeletonShape.RECTANGLE} noOfLines={1} />
        </Stack>
        <Spacer />
        <Spacer />
        <Stack width="35%" margin="5">
          <SkeletonVVTIV type={SkeletonShape.TEXT} noOfLines={1} />
          <SkeletonVVTIV type={SkeletonShape.RECTANGLE} noOfLines={1} />
        </Stack>
      </Flex>
    </Box>
  );
};

export default ProfileView;
