import { extendTheme } from '@chakra-ui/react';
import { StepsTheme as Steps } from 'chakra-ui-steps';

export const VVTIV_THEME = extendTheme({

  colors: {
    error: 'red.500',
    success: 'green.500',
    primary: {
      200:"#b2966b",
      300:"#b08f5c",
      400: '#85683c',
      500: '#947443',
      600: '#9f8256',
      700: '#5b5b7d',
      800: '#70708e',
      900: '#84849e',
    },
    secondary: {
      400: '#2d2d54',
      500: '#32325d',
      600: '#47476d',
    },
  },
  components: {
    Steps,
    Button: {
      // 1. We can update the base styles
      baseStyle: {
        borderRadius: '0', // Normally, it is "semibold"
      },

    
  }
}
});
