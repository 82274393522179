import Client from '../../../../shared/utils/client';
import { GetEventsListResponse } from './domain/response/getEventsList.response';
import { GetEventsListRequest } from './domain/request/getEventsList.request';
import { GetEventByIdResponse } from './domain/response/getEventById.response';
import { CreateEventRequest } from './domain/request/createEvent.request';
import { CreateEventResponse } from './domain/response/createEvent.response';

const eventsPrefix = 'event';

export const getEventsListApi = (params: GetEventsListRequest): Promise<GetEventsListResponse> =>
  Client.get(`${eventsPrefix}`, {params: params});

export const getEventByIdApi = (id: string): Promise<GetEventByIdResponse> => Client.get(`${eventsPrefix}/${id}`);

export const createEventApi = (params: CreateEventRequest): Promise<CreateEventResponse> =>
  Client.post(`${eventsPrefix}`, params);

export const participateEventApi = (id: string): Promise<any> => Client.post(`${eventsPrefix}/participate/${id}`);
export const cancelEventApi = (id: string): Promise<{}> => Client.delete(`${eventsPrefix}/participation/${id}`);
