import { createSlice, Slice } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';

export interface GlobalState {}

export const initialState: GlobalState = {};

const GlobalSlice: Slice<GlobalState> = createSlice({
  name: 'global',
  initialState,
  reducers: {},
});

export const GlobalStore = (state: RootState) => state.authentification;
const GlobalReducer = GlobalSlice.reducer;
export default GlobalReducer;
