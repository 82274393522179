import * as Yup from 'yup';

export const eventSchema = Yup.object().shape({
  name: Yup.string().required('Titel is verplicht'),
  description: Yup.string().required('Beschrijving is verplicht'),
  startDate: Yup.string().required('Startdatum is verplicht'),
  endDate: Yup.string().required('Einddatum is verplicht'),
  companyName: Yup.string().required('Bedrijfsnaam is verplicht'),
  houseNumber: Yup.string().required('Huisnummer is verplicht'),
  city: Yup.string().required('Stad is verplicht'),
  //country: Yup.string().required('Country is required'),
  firstName: Yup.string().required('Voornaam is verplicht'),
  lastName: Yup.string().required('Achternaam is verplicht'),
  price: Yup.number().max(999.99).min(1),
});
