import React, { ChangeEventHandler, FC, useMemo, useState } from 'react';
import { getCurrentUser } from '../../../../infrastructure/selector';
import { useSelector } from 'react-redux';
import EditProfileContent from '../../../../components/editProfileContent/editProfileContent';
import { useProfileApi } from '../../../../hooks/useProfileApi';
import { Box, Button, Flex, HStack, Input, Spacer, Spinner, Stack, Tag, TagLabel, Text } from '@chakra-ui/react';
import { AiFillCloseCircle } from 'react-icons/ai';
import FormattedMessage from '../../../../../../../shared/components/translation/formattedMessage';
import { useDebouncedCallback } from 'use-debounce';
import Notification from '../../../../../../../shared/components/notification/notification';
import { useTranslation } from 'react-i18next';

type SuggestionType = { id: number; name: string };
const ProfileEditSpecialties: FC = () => {
  const currentUser = useSelector(getCurrentUser);
  const { patchUserMe, searchSpeciality } = useProfileApi();
  const [inputValue, setInputValue] = useState('');
  const [suggestedResults, setSuggestedResults] = useState<SuggestionType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t: translate } = useTranslation();
  if (!currentUser) {
    return null;
  }

  const handelAddSpecialties = async (item: string) => {
    if (item.length >= 3 && item.length <= 30) {
      await patchUserMe({
        ...currentUser,
        specialties: [...(currentUser.specialties ?? []), item] ?? [],
      });
      setSuggestedResults([]);
      setInputValue('');
    } else {
      Notification.error(translate('profile.settings.professionalDetails.editSpecialties.error.notification'));
      setSuggestedResults([]);
      setInputValue('');
    }
  };

  const handelDeleteSpecialties = (i: number) => {
    let newList = currentUser.specialties.filter((item, index) => index !== i);
    patchUserMe({ ...currentUser, specialties: newList ?? [] });
  };

  const handleSearch = async (query: string) => {
    setIsLoading(true);
    const result = await searchSpeciality(query);
    const transformedResult = result
      .map((name, id) => ({ id, name }))
      .filter(({ name }, i) => !currentUser.specialties.includes(name));
    setSuggestedResults(transformedResult);
    setIsLoading(false);
  };

  const debounced = useDebouncedCallback(handleSearch, 500);
  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const query = event.target.value;
    setInputValue(query);
    if (query.length) {
      debounced(query);
    } else {
      setSuggestedResults([]);
      setInputValue('');
    }
  };

  return (
    <EditProfileContent title={'profile.settings.professionalDetails.editSpecialties.title'}>
      <Flex flexDirection={{base: 'column', sm: 'column', md: 'column', lg: 'row'}} gap={2} justifyContent="space-between">
        <Stack width={{base: '100%', sm: '100%', md: '100%', lg: '75%'}}>
          <Box position="relative">
            <Input value={inputValue} onChange={handleInputChange} placeholder={'PTSS'} name={'ptss'} />

            {isLoading && (
              <Box position="absolute" top="50%" right="1rem" transform="translateY(-50%)">
                <Spinner size="sm" />
              </Box>
            )}

            {suggestedResults.length > 0 && (
              <Box position="absolute" top="100%" left="0" right="0" zIndex="1" boxShadow="md">
                {suggestedResults.map((result) => (
                  <Box
                    key={result.id}
                    py="2"
                    px="4"
                    cursor="pointer"
                    bg={result.name === inputValue ? 'primary.500' : 'white'}
                    _hover={{ bg: 'primary.500', color: 'white' }}
                    onClick={() => handelAddSpecialties(result.name)}
                  >
                    <Text fontWeight="semibold">{result.name}</Text>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Stack>
        <Stack>
          <Button
            onClick={() => {
              handelAddSpecialties(inputValue);
            }}
            borderRadius={0}
            width={{base: '100%', sm: '100%', md: '100%', lg: 'max-content'}}
            variant="solid"
            colorScheme={'primary'}
            color="white"
            loadingText="loading"
            isDisabled={!inputValue}
          >
            <FormattedMessage translationKey={'profile.settings.addItem.button.label'} />
          </Button>
        </Stack>
      </Flex>
      <HStack mt="5" flexWrap={'wrap'}>
        {currentUser.specialties?.map((item, i) => (
          <Tag
            key={item}
            borderRadius="4px                    "
            variant="solid"
            colorScheme="primary"
            style={{
              background: '#89693A',
              boxShadow: ' 0px 1px 2px rgba(68, 68, 68, 0.25)',
              borderRadius: '0',
              height: '33px',
              fontSize: '10.56px',
              padding: '0 10px',
              marginRight: 0,
              marginInlineStart: 0,
              marginLeft: 10,
              marginTop: 10,
            }}
          >
            <TagLabel fontSize="1rem">{item}</TagLabel>
            <AiFillCloseCircle
              onClick={() => {
                handelDeleteSpecialties(i);
              }}
              style={{ marginLeft: 10, fontSize: 15, cursor: 'pointer' }}
            />
          </Tag>
        ))}
      </HStack>
    </EditProfileContent>
  );
};

export default ProfileEditSpecialties;
