import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { GetNewsResponse } from '../domain/response/getNews.response';

export interface NotificationState {
  loading: boolean;
  news?: any[];
}
export const initialState: NotificationState = {
  loading: false,
  news: [],
};

const NotificationsSlice: Slice<NotificationState> = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<GetNewsResponse[]>) => {
      state.news = action.payload;
    },
  },
});
export const { setNotifications } = NotificationsSlice.actions;
const NotificationReducer = NotificationsSlice.reducer;
export default NotificationReducer;
