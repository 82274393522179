import React, { useState, FC, useCallback, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, ContentState, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './EditorText.module.scss';
import { useField } from 'formik';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import FormattedMessage from '../translation/formattedMessage';

interface ITextEditorProps {
  value: string;
  name: string;
  setFieldValue: (val: string) => void;
  toolbarOption?: object;
}

export const EditorText: FC<ITextEditorProps> = ({ value, setFieldValue, name, toolbarOption }) => {
  const [field, meta] = useField(name);

  const prepareDraft = useCallback((value: string) => {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }, []);

  const [editorState, setEditorState] = useState(value ? prepareDraft(value) : EditorState.createEmpty());

  useEffect(() => {
    if (!value?.length) {
      setEditorState(EditorState.createEmpty());
    }
  }, [value]);

  const onEditorStateChange = (editorState: EditorState) => {
    const forFormik = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    if (!editorState.getCurrentContent().hasText()) {
      setFieldValue('');
    } else {
      setFieldValue(forFormik);
    }
    setEditorState(editorState);
  };

  const isInvalid = meta.touched && !!meta.error;

  return (
    <FormControl isInvalid={isInvalid}>
      <Editor
        editorState={editorState}
        toolbarClassName={styles.eventEditorToolbar}
        wrapperClassName={`${styles.eventEditorWrapper} ${isInvalid && styles.eventEditorWrapper__error}`}
        onEditorStateChange={onEditorStateChange}
        toolbar={toolbarOption}
      />
      <FormErrorMessage>
        <FormattedMessage translationKey={meta.error || ''} />
      </FormErrorMessage>
    </FormControl>
  );
};
