import { Button } from '@chakra-ui/button';
import { Box, Flex } from '@chakra-ui/layout';
import { FunctionComponent, MouseEventHandler } from 'react';
import FormattedMessage from '../../../../shared/components/translation/formattedMessage';
import { useTranslation } from 'react-i18next';
import { chakra } from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';
import ProfileEditSpecialties from '../../profile/views/profileEdit/profileEditProfessionalDetails/profileEditSpecialties/profileEditSpecialties';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../profile/infrastructure/selector';
import { Form, Formik } from 'formik';
import EditProfileContent from '../../profile/components/editProfileContent/editProfileContent';
import { EditorText } from '../../../../shared/components/editorText/EditorText';
import { useProfileApi } from '../../profile/hooks/useProfileApi';

const FaArrowRightAlt = chakra(FaArrowRight);

interface StepBasicInfoProps {
  nextStep: MouseEventHandler<HTMLButtonElement>;
  prevStep: MouseEventHandler<HTMLButtonElement>;
}

interface IFirstStepInitialValues {
  remote: string;
  availability: string;
  seo: string;
  seniority: string;
  type: number;
  public: boolean;
}

const StepBasicInfo: FunctionComponent<StepBasicInfoProps> = ({ nextStep, prevStep }) => {
  const { t } = useTranslation();
  const { patchUserMe } = useProfileApi();
  const { t: translate } = useTranslation();

  const currentUser = useSelector(getCurrentUser);
  if (!currentUser) return null;
  return (
    <Box>
      <Formik
        initialValues={{
          specialties: currentUser.specialties,
          aboutMe: currentUser.aboutMe,
        }}
        onSubmit={(values, { setFieldError }) => {}}
      >
        {({ getFieldMeta, setFieldValue, values }) => (
          <Form>
            <Flex flexDirection="column" textAlign="left">
              <ProfileEditSpecialties />
              <EditProfileContent
                title={translate('profile.settings.tellUsAboutYourself.title')}
                minW="100%"
                marginTop={45}
              >
                <EditorText
                  name={'aboutMe'}
                  setFieldValue={(val) => setFieldValue('aboutMe', val)}
                  value={values.aboutMe}
                />
              </EditProfileContent>
            </Flex>

            <Button
              float={'left'}
              colorScheme="primary"
              mt="3rem"
              mb="1rem"
              onClick={async (e) => {
                await patchUserMe({ ...currentUser, aboutMe: values.aboutMe });
                nextStep(e);
              }}
            >
              <FormattedMessage translationKey="setupProfileModal.steps.stepInfo.form.nextStep.button.text" />
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default StepBasicInfo;
