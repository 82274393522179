import { lazy } from 'react';
import { Route } from 'react-router-dom';

const Chat = lazy(() => import('./components'));

export default [
  <Route path="chat">
    <Route path="" element={<Chat />} />
  </Route>,
];
