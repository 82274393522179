import React, { FC } from 'react';
import {
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  InputRightElement,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import { useField } from 'formik';
import FormattedMessage from '../../translation/formattedMessage';
import { FaEuroSign } from 'react-icons/fa';

export interface InputPriceProps {
  name: string;
  placeholder: string;
  min?: number;
  max?: number;
  step?: number;
  precision?: number;
}

export const InputPrice: FC<InputPriceProps> = ({
  name,
  placeholder,
  min = 1,
  max = 999.9,
  step = 0.1,
  precision = 2,
}) => {
  const [field, meta, helpers] = useField(name);

  const parse = (val: string) => {
    const decimalRegex = /(\.\d{0,2})\d*/;
    const decimalMatch = val.match(decimalRegex);
    let truncatedDecimal = decimalMatch ? decimalMatch[1] : '';
    return val.replace(decimalRegex, truncatedDecimal);
  };

  return (
    <FormControl isInvalid={meta.touched && !!meta.error}>
      <InputGroup>
        <NumberInput
          {...field}
          width="100%"
          onChange={(valueString) => helpers.setValue(parse(valueString))}
          max={max}
          min={min}
        >
          <NumberInputField />
        </NumberInput>
        <InputRightElement pointerEvents="none" children={<FaEuroSign />} color="gray.500" />
      </InputGroup>

      <FormErrorMessage>
        <FormattedMessage translationKey={meta.error as string} />
      </FormErrorMessage>
    </FormControl>
  );
};
