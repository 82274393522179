import React, { ElementType, FunctionComponent, ReactNode } from 'react';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import { Flex } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export type StepsProps = { label?: string | ReactNode; StepComponent: ElementType };

interface StepperProps {
  steps: StepsProps[];
  onComplete: () => void;
  LastPage: ElementType;
}

const Stepper: FunctionComponent<StepperProps> = ({ steps, onComplete, LastPage }) => {
  const { t: translate } = useTranslation();

  const { nextStep, activeStep, prevStep } = useSteps({
    initialStep: 0,
  });

  if (activeStep === steps.length) {
    onComplete();
  }

  const getTextByStep = (currentStep: number) => {
    if (currentStep === 0) {
      return translate('setupProfileModal.steps.stepInfo.first');
    }
  };

  return (
    <Flex flexDir="column" width="100%">
      <Steps pb="1.5rem" pt="1.5rem" responsive colorScheme={'primary'} activeStep={activeStep}>
        {steps.map(({ label, StepComponent }, index) => (
          <Step label={label} key={`${index}`}>
            <Text mb="2.5" ml="2.5">
              {getTextByStep(activeStep)}
            </Text>
            <StepComponent nextStep={nextStep} prevStep={prevStep} />
          </Step>
        ))}
      </Steps>
      {activeStep === steps.length && (
        <Flex px={4} marginTop="45" marginBottom="25" width="100%" flexDirection="column">
          <LastPage />
        </Flex>
      )}
    </Flex>
  );
};

export default Stepper;
