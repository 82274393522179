import { Selector } from '../../../../shared/domain/selector';
import { ConnectState } from '../../connect/infrastructure/store';

const getConnectState: Selector<ConnectState> = (state) => state?.connect;

export const selectConnectLoading: Selector<boolean> = (state) => getConnectState(state).loading;
export const selectConnectError: Selector<Error | undefined> = (state) => getConnectState(state)?.error;
export const selectAllUsers: Selector<any | undefined> = (state) => getConnectState(state).users;
export const selectActiveUser: Selector<any | undefined> = (state) => getConnectState(state).activeUser;
export const selectPendingInvitations: Selector<any | undefined> = (state) => getConnectState(state).pendingInvitations;
export const selectDeclinedInvitations: Selector<any | undefined> = (state) =>
  getConnectState(state).declinedInvitations;
export const selectPendingInvitationsAsTarget: Selector<any | undefined> = (state) =>
  getConnectState(state).pendingInvitationsAsTarget;
export const selectDeclinedInvitationsAsTarget: Selector<any | undefined> = (state) =>
  getConnectState(state).declinedInvitationsAsTarget;
export const selectConnectTotalPage: Selector<number> = (state) => getConnectState(state).totalPage;

export const selectConnectPage: Selector<number> = (state) => getConnectState(state).page;
export const selectConnectLimit: Selector<number> = (state) => getConnectState(state)?.limit;
