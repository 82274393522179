import { useDispatch } from 'react-redux';
import { getNews } from '../infrastructure/api';
import { setNotifications } from '../infrastructure/store';

interface UseNotificationsApiReturn {
  getNotifications: () => Promise<void>;
}

export const useNotificationsApi = (): UseNotificationsApiReturn => {
  const dispatch = useDispatch();
  const getNotifications: UseNotificationsApiReturn['getNotifications'] = async () => {
    const result = await getNews();
    dispatch(setNotifications(result));
  };

  return { getNotifications };
};
