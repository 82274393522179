import { Box, Skeleton, SkeletonCircle, SkeletonText, Flex } from '@chakra-ui/react';
import { FunctionComponent, ReactNode, useState } from 'react';
import { SkeletonShape } from '../../enums/skeletonShape';

interface SkeletonVVTIVProps {
  children?: ReactNode;
  isList?: boolean;
  listLength?: number;
  listDirection?: 'row' | 'column';
  type: SkeletonShape;
  color?: string;
  noOfLines?: number;
  height?: string;
  width?: string;
  size?: string;
}

const SkeletonVVTIV: FunctionComponent<SkeletonVVTIVProps> = ({
  children,
  isList,
  listLength,
  listDirection = 'row',
  type,
  color = 'primary',
  noOfLines = 4,
  height = '333px',
  width = '100%',
  size = '10',
}) => {
  const fillArray = (): number[] => {
    const fakeArray = [];
    for (let i = 0; i < (listLength || 1); i++) {
      fakeArray.push(i);
    }
    return fakeArray;
  };

  const getShape = {
    [SkeletonShape.CIRCLE]: <SkeletonCircle size={size} color={color} />,
    [SkeletonShape.TEXT]: (
      <SkeletonText color={color} mt="4" noOfLines={noOfLines} spacing="4" skeletonHeight="2" width={width} />
    ),
    [SkeletonShape.RECTANGLE]: (
      <Skeleton color={color}>
        <Box w={width} h={height}></Box>
      </Skeleton>
    ),
    [SkeletonShape.IMAGE]: (
      <Skeleton color={color}>
        <Box w={width} h={height}></Box>
      </Skeleton>
    ),
  };

  const displayContent = () => {
    return (
      <Box my="2" padding="6" bg="white">
        {getShape[type]}
      </Box>
    );
  };

  const [listItems, setListItems] = useState(fillArray());

  return isList ? (
    <Flex flexDirection={listDirection}>{listItems.map((item) => displayContent())}</Flex>
  ) : (
    displayContent()
  );
};
export default SkeletonVVTIV;
