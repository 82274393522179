import { Selector } from '../../../../shared/domain/selector';
import { ProfileState } from './domain/profile.state';
import { UserModel } from './domain/currentUser.model';
import { GetChargeResponse } from './domain/response/ChargeResponse';
import { EducationModel } from './domain/education.model';
import { AdressModal } from './domain/AdressModal';

const getProfileState: Selector<ProfileState> = (state) => state?.profile;

export const getLoading: Selector<boolean> = (state) => getProfileState(state).loading;
export const getError: Selector<Error | undefined> = (state) => getProfileState(state)?.error;
export const getCurrentUser: Selector<UserModel | undefined> = (state) => getProfileState(state).currentUser;
export const getEducationList: Selector<EducationModel[] | undefined> = (state) =>
  getProfileState(state).educationList || [];
export const getAddressList: Selector<AdressModal[] | undefined> = (state) => getProfileState(state).addresses || [];
export const getPreferredAddressSelected: Selector<AdressModal | undefined> = (state) => getProfileState(state).preferredAddress || undefined;
export const getCharge: Selector<GetChargeResponse[] | undefined> = (state) => getProfileState(state).chargeList || [];
