import { Fragment, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

export interface FormattedMessageProps {
  translationKey: string;
  upperCase?: boolean;
  value?: Record<string, string | number | undefined>;
  boldText?: string
}

const FormattedMessage: FunctionComponent<FormattedMessageProps> = ({ translationKey, value = {}, upperCase, boldText }) => {
  const { t } = useTranslation();
  return (
    <Fragment>{upperCase ? t(translationKey, { ...value }).toUpperCase() : boldText ? <> {t(translationKey)} <b>{t(boldText)}</b></> : t(translationKey, { ...value })}</Fragment>
  );
};

export default FormattedMessage;
