import { Button, Card, CardBody, Checkbox, Heading, Text } from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import HeadTitle from '../../../../../shared/components/headTitle/headTitle';
import BaseContainer from '../../../../../shared/components/container/container';
import CustomInput from '../../../../../shared/components/formik/input/input';
import { Form, Formik } from 'formik';
import { eventSchema } from '../../validateSchema';
import { EditorText } from '../../../../../shared/components/editorText/EditorText';
import { Flex } from '@chakra-ui/layout';
import { UploadImage } from '../../../../../shared/components/uploadImage/uploadImage';
import { InputDate } from '../../../../../shared/components/formik/inputDate/inputDate';
import SwitchInput from '../../../../../shared/components/formik/switch-input/switch-input';
import { useEventsApi } from '../../hooks/useEventsApi';
import { setPrivateTitle } from '../../../infrastructure/store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getCurrentUser } from '../../../profile/infrastructure/selector';
import { UserRoles } from '../../../../../shared/enums/userRoles';
import { Navigate } from 'react-router-dom';
import { EventsStatus } from '../../../../../shared/enums/events/eventsType';
import Notification from '../../../../../shared/components/notification/notification';
import { InputPrice } from '../../../../../shared/components/formik/inputPrice/inputPrice';
import { useTranslation } from 'react-i18next';

export interface IInitialValues {
  name: string;
  description: string;
  startDate: Date;
  endDate: Date;
  isOnline: boolean;
  externalOrganization: boolean;
  link?: string;
  organizationName?: string;
  street?: string;
  houseNumber: string;
  companyName: string;
  companyWebsite?: string;
  city: string;
  country: string;
  firstName: string;
  lastName: string;
  price?: number;
  coverImageUrl?: string;
  type: EventsStatus;
}

const EventCreateContainer: FC = () => {
  const { createEvent } = useEventsApi();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(getCurrentUser);
  const [endDateMinDate, setEndDateMinDate] = useState('');
  const { t: translate } = useTranslation();

  useEffect(() => {
    dispatch(setPrivateTitle({ title: translate('event.add.new.event') }));
  }, [translate]);

  const getPrice = (value: any): any => {
    return value ? +value : value;
  };

  if (currentUser?.role !== UserRoles.ADMIN) {
    return <Navigate replace to="/private" />;
  }
  return (
    <BaseContainer centerContent>
      <HeadTitle title={'Event Create'} />
      <Card
        boxShadow={'0px 0px 32px rgba(136, 152, 170, 0.15)'}
        borderRadius={'6px'}
        minW={{ base: '100%', md: '900px' }}
      >
        <CardBody>
          <Heading as="h2" size="xl">
            {translate('event.creation.title')}
          </Heading>
          <Formik<IInitialValues>
            initialValues={{
              name: '',
              description: '',
              startDate: new Date(),
              endDate: new Date(),
              isOnline: false,
              externalOrganization: false,
              link: '',
              organizationName: '',
              street: '',
              houseNumber: '',
              companyName: '',
              companyWebsite: '',
              city: '',
              country: 'Belgium',
              firstName: '',
              lastName: '',
              type: EventsStatus.OFFLINE,
            }}
            validationSchema={eventSchema}
            onSubmit={async (values, { setFieldError }) => {
              values.type = values.isOnline ? EventsStatus.ONLINE : EventsStatus.OFFLINE;
              if (await createEvent({ ...values, price: getPrice(values.price) })) {
                Notification.success('Event is Created!');
                navigate('..');
              }
            }}
          >
            {({ handleSubmit, setFieldValue, values, handleReset }) => (
              <Form onSubmit={handleSubmit}>
                <Flex gap={'1rem'} flexDirection={'column'}>
                  <CustomInput placeholder={translate('event.creation.title.placeholder')} name={'name'} type="text" />
                  <EditorText
                    name={'description'}
                    setFieldValue={(val) => setFieldValue('description', val)}
                    value={values.description}
                  />
                  <Flex flexDirection={'column'} gap={'0.5rem'}>
                    <Flex alignItems={'center'} justifyContent={'space-between'}>
                      <Text fontSize="md" fontWeight={'bold'}>
                        {translate('event.creation.upload.title')}
                      </Text>
                      <Text fontSize="sm" fontWeight={'bold'}>
                        ({translate('event.creation.title.upload.maximum')})
                      </Text>
                    </Flex>
                    <UploadImage onFileUpload={(filePath) => setFieldValue('coverImageUrl', filePath)} />
                  </Flex>

                  <Flex gap={'1rem'} width={'100%'}>
                    <Flex gap={'0.5rem'} width={'100%'} flexDirection={'column'}>
                      <Text fontSize="md" fontWeight={'bold'}>
                        {translate('event.creation.startDate')}
                      </Text>
                      <InputDate
                        name={'startDate'}
                        onChange={(value) => {
                          setFieldValue('startDate', value);
                          setEndDateMinDate(value);
                        }}
                      />
                    </Flex>
                    <Flex gap={'0.5rem'} width={'100%'} flexDirection={'column'}>
                      <Text fontSize="md" fontWeight={'bold'}>
                        {translate('event.creation.endDate')}
                      </Text>
                      <InputDate
                        name={'endDate'}
                        minDate={endDateMinDate}
                        onChange={(value) => setFieldValue('endDate', value)}
                      />
                    </Flex>
                  </Flex>

                  <Flex gap={'1rem'} width={'100%'}>
                    <SwitchInput
                      onChange={() => setFieldValue('isOnline', !values.isOnline)}
                      isBold
                      size="lg"
                      name="isOnline"
                      isChecked={values.isOnline}
                      label={translate('event.creation.onlineEvent') as string}
                    />
                    <SwitchInput
                      onChange={() => setFieldValue('externalOrganization', !values.externalOrganization)}
                      isBold
                      size="lg"
                      name="externalOrganization"
                      isChecked={values.externalOrganization}
                      label={translate('event.creation.ExterneOrganisator') as string}
                    />
                  </Flex>

                  <Flex gap={'1rem'} width={'100%'}>
                    <CustomInput
                      placeholder={translate('event.creation.webinar.link.placeholder')}
                      disabled={!values.isOnline}
                      name={'link'}
                      type="text"
                    />
                    <CustomInput
                      placeholder={translate('event.creation.organization.name')}
                      disabled={!values.externalOrganization}
                      name={'organizationName'}
                      type="text"
                    />
                  </Flex>

                  <Flex gap={'1rem'} width={'100%'}>
                    <Flex gap={'0.5rem'} width={'100%'} flexDirection={'column'}>
                      <Text fontSize="md" fontWeight={'bold'}>
                        {translate('event.creation.location.placeholder')}
                      </Text>
                      <CustomInput
                        placeholder={translate('event.creation.location.street.placeholder')}
                        name={'street'}
                        type="text"
                      />
                    </Flex>
                    <Flex gap={'0.5rem'} width={'100%'} flexDirection={'column'} justifyContent={'flex-end'}>
                      <CustomInput
                        placeholder={translate('event.creation.location.houseNumber.placeholder')}
                        name={'houseNumber'}
                        type="text"
                      />
                    </Flex>
                  </Flex>
                  <Flex gap={'1rem'} width={'100%'}>
                    <CustomInput
                      placeholder={translate('event.creation.location.companyName.placeholder')}
                      name={'companyName'}
                      type="text"
                    />
                    <CustomInput
                      placeholder={translate('event.creation.location.companyWebsite.placeholder')}
                      name={'companyWebsite'}
                      type="text"
                    />
                  </Flex>
                  <Flex gap={'1rem'} width={'100%'}>
                    <CustomInput
                      placeholder={translate('event.creation.location.city.placeholder')}
                      name={'city'}
                      type="text"
                    />
                    <CustomInput
                      placeholder={'Country'}
                      defaultValue={values.country}
                      disabled={true}
                      name={'country'}
                      type="text"
                    />
                  </Flex>

                  <Flex gap={'1rem'} width={'100%'} mt={'2rem'}>
                    <Flex gap={'0.5rem'} width={'100%'} flexDirection={'column'}>
                      <Text fontSize="md" fontWeight={'bold'}>
                        {translate('event.creation.instructor')}
                      </Text>
                      <CustomInput
                        placeholder={translate('event.creation.instructor.firstName')}
                        name={'firstName'}
                        type="text"
                      />
                    </Flex>
                    <Flex gap={'0.5rem'} width={'100%'} flexDirection={'column'} justifyContent={'flex-end'}>
                      <CustomInput
                        placeholder={translate('event.creation.instructor.lastName')}
                        name={'lastName'}
                        type="text"
                      />
                    </Flex>
                  </Flex>
                  <Flex gap={'1rem'} width={'100%'}>
                    <InputPrice name={'price'} placeholder={translate('event.creation.price')} min={1} max={999.99} />
                    <Checkbox
                      name={'payingEvent'}
                      value={'true'}
                      colorScheme={'primary'}
                      size="md"
                      color="#ADB5BD"
                      width={'100%'}
                    >
                      {translate('event.creation.paiedEvent')}
                    </Checkbox>
                  </Flex>

                  <Flex gap={'10rem'} mt={'3rem'}>
                    <Button
                      borderRadius={0}
                      type="reset"
                      onClick={handleReset}
                      variant="solid"
                      bg={'#D8D8D8'}
                      width="full"
                      color={'white'}
                    >
                      {translate('event.creation.button.restart')}
                    </Button>
                    <Button borderRadius={0} type="submit" variant="solid" colorScheme={'primary'} width="full">
                      {translate('event.creation.button.add')}
                    </Button>
                  </Flex>
                </Flex>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </BaseContainer>
  );
};

export default EventCreateContainer;
