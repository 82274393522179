import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { InsuranceModel } from './domain/insurance.model';
import { InvoiceModel } from './domain/invoice.model';
import { AuthentificationState } from './domain/auth.state';
import { loadingValidateResponse } from './domain/response/validate.response';

export const initialState: AuthentificationState = {
  isAuthenticated: false,
  loading: false,
  hasErrors: false,
  insurances: [],
  firstLogin: false,
  checkout: {
    memberInvoice: null,
    insuranceInvoice: [],
    totalPrice: 0,
  },
  loadingValidate: false,
  loadingValidateResponse: {
    countryCode: '',
    vatNumber: '',
    requestDate: '',
    valid: false,
    name: '',
    address: ''
  },
  showIcon: '',
};

const AuthentificationSlice: Slice<AuthentificationState> = createSlice({
  name: 'authentification',
  initialState,
  reducers: {
    setAuthLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFirstLogin: (state, action: PayloadAction<boolean>) => {
      state.firstLogin = action.payload;
    },
    loginUser: (state, action: PayloadAction) => {
      state.isAuthenticated = true;
      // TODO: ADD USER INFO
    },
    logoutUser: (state, action: PayloadAction) => {
      state.isAuthenticated = false;
      // TODO: REMOVE USER INFO
    },
    setInsurances: (state, action: PayloadAction<InsuranceModel[]>) => {
      state.insurances = action.payload;
    },
    setError: (state, action: PayloadAction<Error>) => {
      state.error = action.payload;
    },
    setMemberInvoice: (state, action: PayloadAction<InvoiceModel | null>) => {
      state.checkout.memberInvoice = action.payload;
    },
    setInsuranceInvoice: (state, action: PayloadAction<InvoiceModel[]>) => {
      state.checkout.insuranceInvoice = action.payload;
    },
    setTotalPrice: (state, action: PayloadAction<number>) => {
      state.checkout.totalPrice = action.payload;
    },
    setAuthValidate: (state, action: PayloadAction<boolean>) => {
      state.loadingValidate = action.payload;
    },
    setAuthValidateResponse: (state, action: PayloadAction<loadingValidateResponse>) => {
      state.loadingValidateResponse = action.payload;
    },
    setShowIcon: (state, action: PayloadAction<string>) => {
      state.showIcon = action.payload
    },
  },
});

export const {
  setAuthLoading,
  loginUser,
  logoutUser,
  setInsurances,
  setError,
  setMemberInvoice,
  setInsuranceInvoice,
  setTotalPrice,
  setFirstLogin,
  setAuthValidate,
  setAuthValidateResponse,
  setShowIcon,
} = AuthentificationSlice.actions;

const AuthentificationReducer = AuthentificationSlice.reducer;
export default AuthentificationReducer;
