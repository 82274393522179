import { FunctionComponent } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Header from '../../shared/layout/header';
import { isLoggedInUser } from '../../shared/utils/isLoggedInUser';

const AuthenticationContainer: FunctionComponent = () => {
  const location = useLocation();
  const isProfile = location.pathname.includes('profile');
  const isZoeken = location.pathname.includes('zoeken');

  if (isLoggedInUser() && !isZoeken && !isProfile) {
    return <Navigate to={'/private'} />;
  }

  return (
    <>
      {!isProfile && <Header />} <Outlet />
    </>
  );
};
export default AuthenticationContainer;
