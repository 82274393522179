import { lazy } from 'react';
import { Route } from 'react-router-dom';
import editProfileRoute from './views/profileEdit/routes';

const ProfileShow = lazy(() => import('./views/profileShow/profileShow.container'));

export default [
  <Route path="profile">
    <Route path="" element={<ProfileShow />} />
    {editProfileRoute}
  </Route>,
];
