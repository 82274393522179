import { Card } from '@chakra-ui/card';
import { Divider, Flex, Heading, Text } from '@chakra-ui/layout';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LANGUAGES } from '../../../../../config/languages';
import FormattedMessage from '../../../../shared/components/translation/formattedMessage';
import { getCurrentUser } from '../../profile/infrastructure/selector';
import SpecialtiesTagsItem from '../specialties-tags/specialties-tags-item';
import { selectActiveUser } from '../../connect/infrastructure/selectors';
import Empty from 'app/shared/components/Empty/Empty';

interface LanguagesTagsProps {}

const LanguagesTags: FC<LanguagesTagsProps> = () => {
  const currentUser = useSelector(getCurrentUser);
  const activeUser = useSelector(selectActiveUser);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    if (activeUser) {
      setUser(activeUser);
    } else {
      setUser(currentUser);
    }
  }, [activeUser]);

  let objectLangues = Object.assign({}, ...LANGUAGES.map((lan) => ({ [lan.value]: lan.label })));

  return (
    <Card width="100%">
      <Heading size="md" textAlign="left" padding={25}>
        <FormattedMessage translationKey={'profile.language.title'} />
      </Heading>
      <Divider mb="5" borderColor="gray.200" />
      {user && user.languages && !user.languages.length && <Empty label="profile.language.empty.text" />}
      {user && user.languages && user.languages.length ? (
        <Flex flexWrap="wrap">
          {user?.languages.map((lang: string) => (
            <SpecialtiesTagsItem keyItem={lang} name={objectLangues[lang]} />
          ))}
        </Flex>
      ) : null}
    </Card>
  );
};

export default LanguagesTags;
