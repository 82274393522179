import { useDispatch } from 'react-redux';
import { setAddressList, setPreferredAddress, setCurrentUser, setEducationList, setProfileLoading } from '../infrastructure/store';
import {
  addAddressApi,
  deleteAddressApi,
  deleteEducationApi,
  editEducationApi,
  getAddressApi,
  getEducationApi,
  getEducationsByUserApi,
  getUserMeApi,
  patchAddressApi,
  patchUserMeApi,
  postEducationApi,
  preferredAddressApi,
  searchSpecialityApi,
  parchPreferredAddressApi
} from '../infrastructure/api';
import { PatchUserMeRequest } from '../infrastructure/domain/request/patchUserMe.request';
import { PostEducationRequest } from '../infrastructure/domain/request/postEducation.request';
import { AdressModal } from '../infrastructure/domain/AdressModal';
import { EducationModel } from '../infrastructure/domain/education.model';

interface UseProfileApiReturn {
  getUserMe: () => Promise<void>;
  patchUserMe: (body: PatchUserMeRequest) => Promise<void>;
  createEducation: (body: PostEducationRequest) => Promise<void>;
  getEducation: () => Promise<void>;
  deleteEducation: (id: string) => Promise<void>;
  editEducation: (id: string, body: PostEducationRequest) => Promise<void>;
  searchSpeciality: (searchText: string) => Promise<string[]>;
  getAddress: () => Promise<void>;
  createAddress: (body: AdressModal) => Promise<void>;
  editAddress: (id: string, body: AdressModal) => Promise<void>;
  deleteAddress: (id: string) => Promise<void>;
  getEducationsByUser: (id: string) => Promise<EducationModel[]>;
  getPreferredAddress: () => Promise<void>;
  patchPreferredAddress: (id: string) => Promise<void>;
}

export const useProfileApi = (): UseProfileApiReturn => {
  const dispatch = useDispatch();

  const getUserMe: UseProfileApiReturn['getUserMe'] = async () => {
    const result = await getUserMeApi();
    dispatch(setCurrentUser(result));
  };

  const patchUserMe: UseProfileApiReturn['patchUserMe'] = async (body) => {
    const result = await patchUserMeApi(body);
    dispatch(setCurrentUser(result));
  };

  const getEducation: UseProfileApiReturn['getEducation'] = async () => {
    const result = await getEducationApi();
    dispatch(setEducationList(result));
  };

  const createEducation: UseProfileApiReturn['createEducation'] = async (body) => {
    await postEducationApi(body);
    await getEducation();
  };

  const deleteEducation: UseProfileApiReturn['deleteEducation'] = async (id: string) => {
    await deleteEducationApi(id);
    await getEducation();
  };

  const editEducation: UseProfileApiReturn['editEducation'] = async (id: string, body: PostEducationRequest) => {
    await editEducationApi(id, body);
    await getEducation();
  };

  const getAddress: UseProfileApiReturn['getAddress'] = async () => {
    const result = await getAddressApi();
    dispatch(setAddressList(result));
  };

  const createAddress: UseProfileApiReturn['createAddress'] = async (body: AdressModal) => {
    dispatch(setProfileLoading(true));
    await addAddressApi(body);
    await getAddressApi();
    dispatch(setProfileLoading(false));
  };

  const deleteAddress: UseProfileApiReturn['deleteAddress'] = async (id: string) => {
    await deleteAddressApi(id);
    await getAddressApi();
  };

  const editAddress: UseProfileApiReturn['editAddress'] = async (id: string, body: AdressModal) => {
    await patchAddressApi(id, body);
    await getAddressApi();
  };

  const searchSpeciality: UseProfileApiReturn['searchSpeciality'] = async (searchText) => {
    return await searchSpecialityApi(searchText);
  };

  const getEducationsByUser: UseProfileApiReturn['getEducationsByUser'] = async (id: string) => {
    return await getEducationsByUserApi(id);
  };

  const getPreferredAddress: UseProfileApiReturn['getPreferredAddress'] = async () => {
    const result = await preferredAddressApi();
    dispatch(setPreferredAddress(result));
  }

  const patchPreferredAddress: UseProfileApiReturn['patchPreferredAddress'] = async (id: string) => {
    await parchPreferredAddressApi(id);
    await getAddressApi();
  }

  return {
    getUserMe,
    patchUserMe,
    createEducation,
    getEducation,
    deleteEducation,
    searchSpeciality,
    editEducation,
    getAddress,
    createAddress,
    deleteAddress,
    editAddress,
    getEducationsByUser,
    getPreferredAddress,
    patchPreferredAddress
  };
};
