import { Selector } from '../../../shared/domain/selector';
import { InsuranceModel } from './domain/insurance.model';
import { InvoiceModel } from './domain/invoice.model';
import { AuthentificationState } from './domain/auth.state';
import { loadingValidateResponse } from './domain/response/validate.response';

const getAuthentificationState: Selector<AuthentificationState> = (state) => state?.authentification;

export const getLoading: Selector<boolean> = (state) => getAuthentificationState(state).loading;
export const getHasErrors: Selector<boolean> = (state) => getAuthentificationState(state).hasErrors;
export const getError: Selector<Error | undefined> = (state) => getAuthentificationState(state)?.error;
export const getIsAuthenticated: Selector<boolean> = (state) => getAuthentificationState(state)?.isAuthenticated;
export const getInsurances: Selector<InsuranceModel[]> = (state) => getAuthentificationState(state).insurances;
export const getMemberInvoice: Selector<InvoiceModel | null> = (state) =>
  getAuthentificationState(state).checkout.memberInvoice;
export const getInsuranceInvoice: Selector<InvoiceModel[]> = (state) =>
  getAuthentificationState(state).checkout.insuranceInvoice;
export const getTotalPrice: Selector<number> = (state) => getAuthentificationState(state).checkout.totalPrice;
export const getFirstLogin: Selector<boolean> = (state) => getAuthentificationState(state).firstLogin;
export const getLoadingValidate: Selector<boolean> = (state) => getAuthentificationState(state).loadingValidate;
export const getAuthValidateResponse: Selector<loadingValidateResponse> = (state) => getAuthentificationState(state).loadingValidateResponse;
export const getShowIcon: Selector<string> = (state) => getAuthentificationState(state).showIcon
